import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { getCurrentLanuage, getTxt } from '@data/locales';

const JobalertForm = ({ title }) => {
  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sector, setSector] = useState('');
  const [cv, setCV] = useState('');

  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { name: name, email: email, phone: phone, sector: sector, cv: cv };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/contact-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_s6410g2', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(name == '' || email == '' || sector == '' || cv == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
          <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title }</h3>
            <span className="description" style={{ marginBottom: 15 }}>
              { getTxt('forms', 'firstOne') }
            </span>
            <label>{ getTxt('forms', 'full_name') }*</label>
            <input type="text" name="name" onChange={ (event) => setName(event.target.value) } />
            <label>{ getTxt('forms', 'email') }*</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />
            <label>{ getTxt('forms', 'phone') }</label>
            <input type="text" name="phone" onChange={ (event) => setPhone(event.target.value) } />
            <label>{ getTxt('forms', 'links') }*</label>
            <input type="text" name="phone" onChange={ (event) => setCV(event.target.value) } />
            <label>{ getTxt('forms', 'sector') }*</label>
            <ul>
              <li className={ (sector == 'bouw') ? 'active' : '' } onClick={ () => setSector('bouw') }>{ getTxt('forms', 'construction') }</li>
              <li className={ (sector == 'finance') ? 'active' : '' } onClick={ () => setSector('finance') }>{ getTxt('forms', 'finance') }</li>
              <li className={ (sector == 'engineering') ? 'active' : '' } onClick={ () => setSector('engineering') }>{ getTxt('forms', 'engineering') }</li>
            </ul>
            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default JobalertForm;