import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import { getCurrentLanuage, getTxt } from '@data/locales';

const MakeAppointmentWerkgeversForm = ({ title }) => {
  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [sector, setSector] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [insearchof, setInsearchof] = useState('');
  const [region, setRegion] = useState('');
  const [phone, setPhone] = useState('');
  const [when, setWhen] = useState('');
  const [optionsSubniches, setOptionsSubniches] = useState(false);

  const options = [{ value: 'finance', label: getTxt('forms', 'finance') },{ value: 'bouw', label: getTxt('forms', 'construction') },{ value: 'engineering', label: getTxt('forms', 'engineering') }];
  const optionsSearch = [{ value: 'new_job', label: 'Nieuwe job' }, { value: 'advice', label: 'Advies' }];

  const [email, setEmail] = useState('');
  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { name: name, company: company, sector: sector.label, email: email, phone: phone };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/contact-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_zqbs3gl', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(name == '' || company == '' || phone == '' || sector == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
          <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title }</h3>
            <label>{ getTxt('forms', 'full_name') } *</label>
            <input type="text" name="name" onChange={ (event) => setName(event.target.value) } />

            <label>{ getTxt('forms', 'company') } *</label>
            <input type="text" name="name" onChange={ (event) => setCompany(event.target.value) } />

            <label>{ getTxt('forms', 'email') }</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />

            <label>{ getTxt('forms', 'mobile') } *</label>
            <input type="number" name="name" onChange={ (event) => setPhone(event.target.value) } />

            <label>{ getTxt('forms', 'vacancy_type') } *</label>
            <Dropdown options={options} value={sector} onChange={ (event) => { setSector(event) } } />

            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default MakeAppointmentWerkgeversForm;