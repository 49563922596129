//libs
import { useState, useEffect, useRef } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Loading from "@components/Loading";
import moment from 'moment';
import Member from "@components/Member";
import MainButton from "@components/MainButton";
import astronaut from '@assets/images/not-found.png';
import axios from 'axios';
import _ from 'underscore';
import Select from 'react-select';
import { getCurrentLanuage, getTxt } from '@data/locales';

const Candidates = ({ section }) => {

  const [content, setContent] = useState(false);
  const [jobs, setJobs] = useState(false);
  const [expert, setExpert] = useState(false);
  const [email, setEmail] = useState('');
  const form = useRef();

  const [niches, setNiches] = useState([]);
  const [subniches, setSubniches] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);

  const [nicheFilter, setNicheFilter] = useState(false);
  const [subnicheFilter, setSubnicheFilter] = useState(false);
  const [postalFilter, setPostalFilter] = useState(false);
  const [specialisatieFilter, setSpecialisatieFilter] = useState(false);

  const [postalName, setPostalName] = useState({1: getTxt('jobs', 'brussel'), 2: getTxt('jobs', 'antwerp'), 3 : getTxt('jobs', 'limburg'), 4: getTxt('jobs', 'luik'), 5: getTxt('jobs', 'namen'), 6: getTxt('jobs', 'luxemburg'), 7: getTxt('jobs', 'henegouwen'), 8: getTxt('jobs', 'brussel'), 9: getTxt('jobs', 'east_flanders') })

  useEffect(() => {
    let populate = '?populate=*&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/werkgevers-kandidaat"+populate)
      .then(res => res.json()).then(content => {
        setContent(content.data.attributes);
    })

    let populate_jobs = '?populate=*&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/kandidaten"+populate_jobs)
      .then(res => res.json()).then(jobs => {
        let _niches = [];
        let _main_niches = [];
        let _postal_codes = [];
        jobs.data.map((val, key) => {
          if(val.attributes.niche.data){
            let typeBedrijf = val.attributes.typeBedrijf.data ? val.attributes.typeBedrijf.data.attributes.category : '';            
            let nicheSpecialisatie = val.attributes.nicheSpecialisatie.data ? val.attributes.nicheSpecialisatie.data.attributes.specialisatie : '';
            _niches.push({ 
              niche: val.attributes.niche.data.attributes.nicheTitle, 
              subniche: typeBedrijf,
              specialisatie : nicheSpecialisatie,
              niche_slug: slugify(val.attributes.niche.data.attributes.nicheTitle),
              subniche_slug: slugify(typeBedrijf),
              specialisatie_slug: slugify(nicheSpecialisatie),
              postal_code: val.attributes.postcode ? val.attributes.postcode : false
            });
            _main_niches.push({
              niche: val.attributes.niche.data.attributes.nicheTitle,
              niche_slug: slugify(val.attributes.niche.data.attributes.nicheTitle),
            })
            if(val.attributes.postcode){
              _postal_codes.push(val.attributes.postcode)
            }
          }
        })
        _niches = _.uniq(_niches, 'subniche_slug');
        _main_niches = _.uniq(_main_niches, 'niche_slug');
        _postal_codes = _.uniq(_postal_codes, false);
        setPostalCodes(_postal_codes);
        setNiches(_main_niches);
        setSubniches(_niches);
        setJobs(jobs.data);
    });

    fetch(process.env.REACT_APP_STRAPI+"/api/teams"+populate)
      .then(res => res.json()).then(team => {
        let random = Math.floor(Math.random() * team.data.length);
        setExpert(team.data[random]);
      })
   }, [section]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { email: email, type: "kandidaat" };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/jobalerts', { data: data });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);
  const validateFields = () => {
    setError(false);
    if(email == ''){
      setError('Gelieve alle velden correct in te vullen');
      return false;
    } else {
      return true;
    }
  }

  const slugify = (str) => {
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }

  const checkNiche = (_niche, _subniche, _postalcode, _specialisatie) => {
    if(!nicheFilter){
      return true;
    }
    if((nicheFilter && !subnicheFilter) && (nicheFilter == slugify(_niche))){
      return true;
    }
    if((nicheFilter && subnicheFilter && !postalFilter && !specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) )){
      return true;
    }
    if((nicheFilter && subnicheFilter && postalFilter && !specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) && postalFilter.includes(_postalcode) )){
      if(!specialisatieFilter || (specialisatieFilter && specialisatieFilter.includes(slugify(_specialisatie)) )) {
        return true;
      }
    }
    if((nicheFilter && subnicheFilter && specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) && specialisatieFilter.includes(slugify(_specialisatie) ) )){
      if(!postalFilter || (postalFilter && postalFilter.includes(_postalcode ) )) {
        return true;
      }
    }
    return false;
  }

  const optionsSubNiches =  (options) => {
    let _options = [];
    options.map((val, key) => {
      _options.push({ value: val.subniche_slug, label: val.subniche })
    })
    return _options;
  }

  const optionsPostal =  (options) => {
    let _options = [];
    options.map((val, key) => {
      _options.push({ value: val.postal_code, label: postalName[parseInt(val.postal_code.charAt(0))] })
    })
    _options = _.uniq(_options, 'value');
    return _options;
  }

  const optionsSpecialisaties =  (options) => {
    let _options = [];
    options.map((val, key) => {
      _options.push({ value: val.specialisatie_slug, label: val.specialisatie })
    })
    _options = _.uniq(_options, 'value');
    return _options;
  }

  const changeSubnicheFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setSubnicheFilter(_options);
  }

  const changePostalFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setPostalFilter(_options);
  }

  const changeSpecialisatieFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setSpecialisatieFilter(_options);
  } 

  return (
    (jobs && content) ? (
      <div className="jobs">
        <section className="headline less-margin">
          <div className="content">
            <div className="half-text">
              <h1><span>{ content.title }</span></h1>
              <span className="intro-text"dangerouslySetInnerHTML={{__html: content.description}} />
            </div>
          </div>
        </section>
        <div className="content">
          <section className="job-section">
              <div className="job-jobs">
                {
                  jobs.map((value, key) => {
                    if(value.attributes.typeBedrijf.data && checkNiche(value.attributes.niche.data.attributes.nicheTitle, value.attributes.typeBedrijf.data.attributes.category, value.attributes.postcode, value.attributes.nicheSpecialisatie.data.attributes.specialisatie)){
                      return (
                        <article className="job" key={ key }>
                          <div className="center">
                            <h4>{ value.attributes.title }</h4>
                            <span className="description">
                              { value.attributes.introText }
                            </span>
                          </div>
                          <div className="top">
                            { value.attributes.niche.data && <span className="tag yellow">{ value.attributes.niche.data.attributes.nicheTitle }</span> }
                            { value.attributes.typeBedrijf.data && <span className="tag transparent">{ value.attributes.typeBedrijf.data.attributes.category }</span> }
                            {/* value.attributes.nicheSpecialisatie.data && <span className="tag transparent">{ value.attributes.nicheSpecialisatie.data.attributes.specialisatie }</span> */}
                          </div>
                          <div className="subline">
                            <span className="date">{ moment(value.attributes.publishedAt).format('MMM D, YYYY') }</span>
                            <span className="location">{ postalName[parseInt(value.attributes.postcode.charAt(0))] }</span>
                            <Link className="btn-watch" to={`/${section}/kandidaten/${value.id}` }>
                              Bekijk job <FeatherIcon icon="arrow-right" />
                            </Link>
                          </div>
                        </article>
                      )
                    }
                  })
                }
              </div>
              <div className="job-filters">
                <h4>Niches</h4>
                <div className="filter" style={{ paddingBottom: 0, marginBottom: 10 }}>
                  <ul className="list-niches niches-menu">
                    {
                      niches.map((value, key) => {
                        return <div key={ key }><li className={ (value.niche_slug == nicheFilter) ? 'active btn-niche btn-'+value.niche_slug : 'btn-niche btn-'+value.niche_slug } onClick={ () => setNicheFilter(nicheFilter ? false : value.niche_slug) } key={ key }>{ value.niche }</li><i>({ subniches.filter(function(_val) {return (_val.niche_slug == value.niche_slug) && (_val.subniche_slug != '') }).length } resultaten)</i></div>
                      })
                    }
                  </ul>
                </div>
                {
                  nicheFilter && (
                    <>
                      <h4>Type Bedrijf</h4>
                      <div className="filter">
                        <Select className="react-select-container" placeholder="Zoek op type bedrijf" onChange={ (_val) => { changeSubnicheFilter(_val) } } isMulti  classNamePrefix="react-select" options={optionsSubNiches(subniches.filter(function(value) {return value.niche_slug == nicheFilter }))} />
                      </div>
                    </>
                  )
                }
                {
                  subnicheFilter && (
                    <>
                      <h4>Specialisatie</h4>
                      <div className="filter">
                        <Select className="react-select-container" placeholder="Zoek op type bedrijf" onChange={ (_val) => { changeSpecialisatieFilter(_val) } } isMulti  classNamePrefix="react-select" options={optionsSpecialisaties(subniches.filter(function(__value) { 
                            //console.log(__value.niche_slug, nicheFilter, subnicheFilter, __value.subniche_slug, __value);
                            return (__value.niche_slug == nicheFilter) 
                          }))} 
                        />
                      </div>
                    </>
                  )
                }
                {
                  ( subnicheFilter ) && (
                    <>
                      <h4>Regio</h4>
                      <div className="filter">
                        <Select className="react-select-container" placeholder="Zoek op regio" onChange={ (_val) => { changePostalFilter(_val) } } isMulti  classNamePrefix="react-select" options={optionsPostal(subniches.filter(function(__value) {
                            console.log(__value.niche_slug, nicheFilter);
                            return (__value.niche_slug == nicheFilter) 
                          }))} 
                        />
                      </div>
                    </>
                  )
                }


                <div className="filter">
                  <div className="jobalert-filter">
                    <h4>Kandidaten</h4>
                    <h5>-</h5>
                  </div>
                  <span className="description">
                    Wil jij als eerste op de hoogte zijn van de beste kandidaten binnen jouw vakgebied?
                  </span>
                  {
                    thanks ? <div className="thanks"><h3>Bedankt, we contacteren u zo spoedig mogelijk</h3></div> : (
                      <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
                        <input type="text" name="email" placeholder="Vul uw email in" onChange={ (event) => setEmail(event.target.value) } />
                        { error && <span className="error">{ error }</span> }
                        <input type="submit" value="Hou me op de hoogte!" className="btn-main" />
                      </form>
                    )
                  }
                </div>
                { expert && (
                  <Member data={ expert.attributes } />
                ) }
              </div>
          </section>
        </div>
        
      </div>
    ) : <Loading />
  );
}

export default Candidates;