//libs
import React from "react";
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import ContactForm from "@components/ContactForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

const WerknemersContact = ({ section }) => {
  return (
    <div className="contact">
      <section className="headline">
        <div className="content">
          <div className="column-2n">
            <div className="column">
            { (section == 'werken') ? (<h1 style={{ marginBottom: 20 }}><span>{ getTxt('contact', 'contactus') }</span></h1>) : (<h1 style={{ marginBottom: 20 }}><span>{ getTxt('contact', 'contact') }</span><span>{ getTxt('contact', 'us') }</span></h1>) }
              <div className="contact-item first">
                {/*<h3>Kom eens langs!</h3>*/}
                {
                  (section == 'werknemers') && (
                    <>
                      <p className="description">
                        { getTxt('contact', 'werknemers') }
                      </p>
                    </>
                  )
                }
                {
                  (section == 'werkgevers') && (
                    <>
                      <p className="description">
                        { getTxt('contact', 'werkgevers') }
                      </p>
                    </>
                  )
                }
                {
                  (section == 'werken') && (
                    <>
                      <p className="description">
                        { getTxt('contact', 'werken') }
                      </p>
                    </>
                  )
                }
                {/*<a href="/werknemers/contact#vestigingen" className="btn-watch">Onze vestigingen <FeatherIcon icon="arrow-right" /></a>*/}
              </div>
              {/*<div className="contact-item second">
                <h3>Werken bij Jorissen & De Jonck</h3>
                <p className="description">
                  Bekijk onze vacatures en vind een job die bij je past.
                </p>
                <a href={ '/'+section+'/'+((section == 'werkgevers') ? 'kandidaten' : (section == 'werknemers') ? 'jobs' : 'solliciteer-nu')  } className="btn-watch">Solliciteer nu <FeatherIcon icon="arrow-right" /></a>
              </div>*/}
            </div>
            <div className="column column-form">
              <ContactForm title={ getTxt('contact', 'intouch') } section={section} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="content">
          <div className="column-2n">
            <div className="column column-map">
              <iframe
                className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.452438948794!2d4.416411952007869!3d51.19231437948449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f6ea555820a1%3A0xa9a1d353146e31e9!2sJorissen%20%26%20De%20Jonck%20BVBA!5e0!3m2!1sen!2sth!4v1671954585174!5m2!1sen!2sth"
                allowFullScreen={false} loading="lazy" />
            </div>
            <div className="column" id="vestigingen">
              <h3>{ getTxt('contact', 'places') }</h3>
              <span className="contact-line">
                <div className="icon"><FeatherIcon color="#FED417" width="18" icon="map-pin" /></div> 
                Uitbreidingstraat 86 2600 Antwerpen ({ getTxt('contact', 'mainseat') })
              </span>
              <span className="contact-line">
                <div className="icon"><FeatherIcon color="#FED417" width="18" icon="map-pin" /></div> 
                Robert Schumanplein 6, 1040 Brussel
              </span>
              
              <span className="contact-line">
                <div className="icon"><FeatherIcon color="#FED417" width="18" icon="phone-call" /></div> 
                03/246.57.70
              </span>
              {/*<span className="contact-line">
                <div className="icon icon-top"><FeatherIcon color="#FED417" width="18" icon="clock" /></div> 
                <strong>Ma – Vr</strong>9:00 – 10:00
              </span>
              <span className="contact-line top">
                <div className="icon icon-top"><FeatherIcon color="#000" width="18" icon="ccc" /></div> 
                <strong>Za – Zo</strong> Gesloten
              </span>*/}
              <div className="socials">
               <a href="https://www.facebook.com/jorissendejonck" target="_blank" className="btn-social btn-facebook">
                  <FeatherIcon width="17" icon="facebook" />
                </a>
                <a href="https://www.linkedin.com/company/jorissen-&-de-jonck/" target="_blank" className="btn-social btn-linkedin">
                  <FeatherIcon width="17" icon="linkedin" />
                </a>
                <a href="https://www.instagram.com/jorissendejonck/" target="_blank" className="btn-social btn-mail">
                  <FeatherIcon width="17" icon="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
        <section className="center">
          <div className="content jobalert">
            <h2>Jobalert</h2>
            <span className="intro-text">Geef aan waarnaar je precies op zoek bent en krijg de ideale jobortunity als eerste in je mailbox.</span>
            <div className="form-newsletter">
              <FeatherIcon width="17" color="#ced8e4" icon="mail" />
              <input type="text" />
              <a href="" className="btn-main">Hou me op de hoogte</a>
            </div>
          </div>
        </section>
      */}
    </div>
  );
}

export default WerknemersContact;