// Import React and necessary components from @react-pdf/renderer
import React from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import styles from './InterimStyle';
import "./Profile.scss";

// Import the local images
import backgroundHeader from './images/background.jpg';
import logo from './images/logo.png';
import lines from './images/lines.png';
import title_lines from './images/title-lines.png';
import fontGeoBold from "./fonts/GeogrotesqueCyr-Bold.ttf";
import fontGeoLight from "./fonts/GeogrotesqueCyr-Light.ttf";
import locationFooter from './images/footer-location-white.png';
import mailFooter from './images/footer-mail-white.png';
import phoneFooter from './images/footer-phone-white.png';
import worldFooter from './images/footer-world-white.png';

import regionIcon from './images/ico-region.png';
import experienceIcon from './images/ico-experience.png';
import knowledgeIcon from './images/ico-knowledge.png';
import dailyIcon from './images/ico-dailyamount.png';
import availableIcon from './images/ico-available.png';

Font.register({
  family: 'Geogrotesque',
  fonts: [
    { src: fontGeoBold, fontWeight: 'bold' },
    { src: fontGeoLight, fontWeight: 'light' }
  ],
});

const ProfileRow = ({ iconSrc, labelText, content, weight = 'light' }) => (
  <View style={styles.profileRow}>
    <View style={styles.iconWrapper}>
      <Image src={iconSrc} style={styles.icon} />
    </View>
    <View style={styles.labelWrapper}>
      <Text style={styles.labelText}>{labelText}</Text>
    </View>
    <View style={styles.lineWrapper}>
      <View style={styles.line} />
    </View>
    <View style={styles.detailsWrapper}>
      <Text style={{ fontWeight: weight }}>{content}</Text>
    </View>
  </View>
);

const ProfileBox = ({ data = {}, lang='nl' }) => (
  <View style={styles.profileBox}>
    <View style={styles.profileBoxHeader}>
      <Image src={title_lines} style={styles.titleLines} />
      <Text style={ styles.profileTitle }>{ data.title?.toUpperCase() }</Text>
    </View>
    <View style={ styles.borderBox }>
      <ProfileRow iconSrc={regionIcon} labelText={ (lang=='nl') ? 'REGIO' : 'REGION' } content={data.region} />
      <ProfileRow iconSrc={experienceIcon} labelText={ (lang=='nl') ? 'ERVARING' : 'REGION' } content={data.experience} />
      <ProfileRow iconSrc={knowledgeIcon} labelText={ (lang=='nl') ? 'SPECIFIEKE KENNIS' : 'REGION' } content={data.knowledge} />
      <ProfileRow iconSrc={dailyIcon} labelText={ (lang=='nl') ? 'DAGTARIEF' : 'REGION' } content={data.daily} />
      <ProfileRow weight="bold" iconSrc={availableIcon} labelText={ (lang=='nl') ? 'BESCHIKBAAR' : 'REGION' } content={data.available} />
    </View>
  </View>
);

const IterimGenerator = ({ content, lang = "nl" }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={backgroundHeader} style={styles.fullBackground} />
        <View style={styles.logoWrapper}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.headerinfoFront}>
          <Text style={styles.headerFrontFirst}>{ content.headline_1 }</Text>
          <View style={ styles.headerFrontSecondWrapper }>
            <Text style={styles.headerFrontSecond}>{ content.headline_2 }</Text>
            <Image src={lines} style={styles.headerFrontSecondLines} />
          </View>
        </View>
      </Page>
      {
        content.talent.map((value, key) => {
          return (
            <Page size="A4" style={styles.page} key={key}>
              <View style={styles.headerPage}>
                <Image src={backgroundHeader} style={styles.backgroundImageHeader} />
                <Image src={logo} style={styles.logoHeader} />
              </View>
              { value.map((profile, k)=> {
                  return <ProfileBox key={k} data={profile} />
              })}
            </Page>
          )
        })
      }
      <Page size="A4" style={styles.page}>
        <Image src={backgroundHeader} style={styles.fullBackground} />
        <View style={styles.logoWrapper}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.headerinfoBack}>
          <Text style={styles.headerBackFirst}>{ content.footer_headline_1 }</Text>
          <Text style={styles.headerBackFirst}>{ content.footer_headline_2 }</Text>
          <Text style={styles.headerBackSecond}>{ content.footer_subline_1 }</Text>
          <Text style={styles.headerBackSecond}>{ content.footer_subline_2 }</Text>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerBlock}>
            <Image src={mailFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>hallo@jorissendejonck.be</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={phoneFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>03 246 57 70</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={locationFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>Uitbreidingstraat 86, 2600 Antwerpen</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={worldFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>jorissenendejonck.be</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default IterimGenerator;