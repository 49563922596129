import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import shape from '@assets/images/shape.svg';
import shape_dark from '@assets/images/logo-black.svg';
import shape_yellow from '@assets/images/logo-yellow.svg';
import Footer from "@components/Layout/Footer";
import { getCurrentLanuage, getTxt, setCurrentLanguage } from '@data/locales';
import CookiePopup from "@components/CookiePopup";

const MainLayout =({children, page, section}) => {
  import('./style.scss');

  const urlParams = new URLSearchParams(window.location.search);

  const [activeHeader, setActiveHeader] = useState(false);

  useEffect(() => {
    if(urlParams.get('lang') == 'nl'){
      setCurrentLanguage('en');
    }
    if(urlParams.get('lang') == 'fr'){
      setCurrentLanguage('fr-BE');
    }
  }, []);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  }

  return(
    <div id="wrapper" className={`section-${section} page-${page}`}>
      <CookiePopup />
      <div className="langmenu hideonmobile">
        <span onClick={ () => changeLanguage('en') } className={'lang ' + (getCurrentLanuage() == 'en' ? 'active' : '') }>NL</span>
        <span onClick={ () => changeLanguage('fr-BE') } className={'lang ' + (getCurrentLanuage() == 'fr-BE' ? 'active' : '') }>FR</span>
      </div>
      <div className="content">
        <header className={ activeHeader ? 'active' : '' }>
          <Link onClick={ () => { setActiveHeader(false) }} to={"/"+section} className="logo">
            <img className="logo" alt="Jorissen & De Jonck" src={ (section == 'werken') ? (page == "home") ? shape_yellow : shape_dark  : shape } width="30" />
          </Link>
          <div className="menu-wrapper">
            <menu className="menu-main">
              <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/over-ons'} className={ (page == 'aboutus') ? 'active' : '' } >{ getTxt('mainMenu', 'aboutus') }</Link>
              { (section !== 'werken') && <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/services'} className={ (page == 'services') ? 'active' : '' } >{ getTxt('mainMenu', 'services') }</Link> }
              { (section == 'werknemers') && <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/jobs'} className={ (page == 'jobs') ? 'active' : '' } >{ getTxt('mainMenu', 'jobs') }</Link>  }
              {/* (section == 'werkgevers') && <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/kandidaten'} className={ (page == 'candidates') ? 'active' : '' } >Kandidaten</Link>  */}
              { (section == 'werken') && <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/solliciteer-nu'} className={ (page == 'apply') ? 'active' : '' } >{ getTxt('mainMenu', 'vacancies') }</Link>  }
              <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/onze-ploeg'} className={ (page == 'team') ? 'active' : '' } >{ getTxt('mainMenu', 'team') }</Link>
              <Link onClick={ () => { setActiveHeader(false) }} to={'/'+section+'/contact'} className={ (page == 'contact') ? 'active' : '' } >{ getTxt('mainMenu', 'contact') }</Link>
            </menu>
            <menu className="menu-sections">
              <Link onClick={ () => { setActiveHeader(false) }} to="/werknemers" className={ (section=='werknemers') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'talent') }</Link>
              <Link onClick={ () => { setActiveHeader(false) }} to="/werkgevers" className={ (section=='werkgevers') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'employers') }</Link>
              <Link onClick={ () => { setActiveHeader(false) }} to="/werken" className={ (section=='werken') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'jdj') }</Link>
            </menu>
            <div className="langmenu-mobile showonmobile">
              <span onClick={ () => changeLanguage('en') } className={'lang ' + (getCurrentLanuage() == 'en' ? 'active' : '') }>NL</span>
              <span onClick={ () => changeLanguage('fr-BE') } className={'lang ' + (getCurrentLanuage() == 'fr-BE' ? 'active' : '') }>FR</span>
            </div>
          </div>
          <div className="mobile-menu" onClick={ () => { setActiveHeader(!activeHeader) } }>
            <div></div><div></div><div></div>
          </div>
        </header>
      </div>
      <main>{ children }</main>
      <Footer page={ section+'-'+page } />
    </div>
  )
}

export default MainLayout;