import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 0,
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  logo: {
    width: 250,
    height: 80,
    margin: 20,
    marginBottom: 30,
    marginTop: 30
  },
  headerinfo: {
    position: 'absolute',
    bottom: -10,
    flexDirection: 'row',
  },
  headerinfoId: {
    backgroundColor: '#FED417',
    fontFamily: 'Geogrotesque',
    fontWeight: 'bold',
    paddingTop: 4,
    paddingBottom: 2,
    paddingHorizontal: 10,
    fontSize: 14
  },
  headerinfoTitle: {
    backgroundColor: '#08182E',
    fontFamily: 'Geogrotesque',
    fontWeight: 'bold',
    paddingTop: 4,
    paddingBottom: 2,
    paddingHorizontal: 10,
    color: '#FFF',
    fontSize: 14
  },
  headerinfoTitleText: {
    color: '#FFF'
  },
  section: {
    marginHorizontal: 40,
    marginTop: 10
  },
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  iconWrapper: {
    width: 25,
    height: 25,
    marginRight: 5,
  },
  labelWrapper: {
    fontFamily: 'Geogrotesque',
    fontWeight: 'bold',
    fontSize: 13,
    padding: 5,
    marginRight: 10,
    flexShrink: 0, 
  },
  lineWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: '#CCC',
  },
  detailsWrapper: {
    width: 330,
    backgroundColor: 'white',
    padding: 5,
    fontFamily: 'Geogrotesque',
    fontWeight: 'light',
    fontSize: 10,
    paddingLeft: 15,
  },
  icon: {
    width: 25,
    height: 25,
  },
  iconFooter: {
    width: 18,
    height: 18,
    marginRight: 2
  },
  circleWrapper: {
    width: 80,  
    height: 80, 
    backgroundColor: '#FED417',
    overflow: 'hidden', 
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    margin: 10,
    flexShrink: 0, 
  },
  circleInner: {
    width: 76,  
    height: 76, 
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
  },
  circleBackground: {
    borderRadius: 50,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  teamMember: {
    marginHorizontal: 40,
    marginTop: 10,
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 50,
    left: 0,
    right: 0
  },
  memberDetails: {
    flex: 1,
    color: '#FFF',
    fontFamily: 'Geogrotesque',
    marginRight: 10,
  },
  memberDetailsName: {
    fontFamily: 'Geogrotesque',
    fontWeight: 'bold',
    fontSize: 13
  },
  memberDetailsFunction: {
    fontFamily: 'Geogrotesque',
    fontWeight: 'light',
    fontSize: 11,
    marginBottom: 5
  },
  memberDetailsEmail: {
    fontFamily: 'Geogrotesque',
    fontWeight: 'light',
    fontSize: 10
  },
  memberBio: {
    color: '#FFF',
    fontFamily: 'Geogrotesque',
    width: 200,
    fontSize: 10,
    marginRight: 20,
    marginLeft: 20
  },
  memberLine: {
    width: 1,
    backgroundColor: '#FFF',
    height: '100%', 
  },
  footer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20,
    left: 40,
    right: 30,
    textAlign: 'center',
    fontSize: 9,
    fontFamily: 'Geogrotesque',
    fontWeight: 'light',
    fontSize: 10,
  },
  footerBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 7
  }
});

export default styles;
