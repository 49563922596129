//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Loading from "@components/Loading";
import ApplyForm from "@components/ApplyForm";

const CandidatesDetails = ({section}) => {

  const [content, setContent] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    let populate_blog = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/kandidaten/"+id+populate_blog)
      .then(res => res.json()).then(candidates => {
        console.log(candidates);
        setContent(candidates.data.attributes);
    })
   }, [section]);

  return (
    content ? (
      <div className="job-details">
        <section className="">
          <div className="content">
            <h2>{ content.title }</h2>
          </div>
          <div className="content">
            <div className="intro-text" dangerouslySetInnerHTML={{__html: content.introText}} />
          </div>
          <div className="job-content">
            <div className="job-text" dangerouslySetInnerHTML={{__html: content.content}} />
          </div>
          <div className="content image-grid">
            <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/cover_1_1d660a1dd4.jpg?updated_at=2022-12-26T17:44:47.469Z)` }}></div>
            <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/cover_02dba5bdd8.jpg?updated_at=2022-12-26T17:44:47.130Z)` }}></div>
          </div>
        </section>
        {/*<div className="content content-form">
          <div className="column column-form">
            <ApplyForm title={ `Solliciteer voor ${content.title}`} />
            
          </div>
        </div>*/}
      </div>
    ) : <Loading />
  );
}

export default CandidatesDetails;