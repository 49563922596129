//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Loading from "@components/Loading";
import ApplyForm from "@components/ApplyForm";
import Member from "@components/Member";
import { getCurrentLanuage, getTxt } from '@data/locales';

const JobsDetails = ({section}) => {

  const [content, setContent] = useState(false);
  const {id} = useParams();
  const [expert, setExpert] = useState(false);
  const [mainExpert, setMainExpert] = useState(false);

  useEffect(() => {
    let populate = '?populate=*&locale='+getCurrentLanuage();
    
    fetch(process.env.REACT_APP_STRAPI+"/api/jobs/"+id+populate)
      .then(res => res.json()).then(jobs => {
        setContent(jobs.data.attributes);
        if(jobs.data.attributes.member){
          let member_id = jobs.data.attributes.member.data.id;
          fetch(process.env.REACT_APP_STRAPI+"/api/teams/"+member_id+populate+'&sort=id&locale='+getCurrentLanuage())
            .then(res => res.json()).then(team => {
              setMainExpert(team.data);
            }
          );
        }
    })
   }, [section]);

  useEffect(() => {
    let populate = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/teams"+populate+'&sort=id&locale='+getCurrentLanuage())
      .then(res => res.json()).then(team => {
        if(content){
          let _found = false;
          let _postCode = parseInt(content.postcode.trim().charAt(0))+'000';
          let _speciali = slugify(content.typeBedrijf.data.attributes.category);
          team.data.map((member, key) => {
            if(member.attributes.niche_categories.data){
              member.attributes.niche_categories.data.map((_niche, _k) => {
                let _postal_codes = member.attributes.postalCode ? member.attributes.postalCode.replace(/\s+/, "").split(",") : [];
                if((slugify(_niche.attributes.category) == _speciali) && _postal_codes.includes(_postCode)){
                  _found = member;
                }
              })
              if(!_found) {
                let random = Math.floor(Math.random() * 2)
                setExpert(team.data[random]);
              } else {
                setExpert(_found);
              }
            }
          })
        }     
      })
  }, [content])

  const slugify = (str) => {
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }

  return (
    content ? (
      <div className="job-details">
        <section className="">
          
          {/*<div className="content">
            
          </div><div className="content">
            <div className="intro-text" dangerouslySetInnerHTML={{__html: content.introText}} />
          </div>*/}
          <div className="job-content white">
            <div className="job-text">
              <h2>{ content.title }</h2>
              <div dangerouslySetInnerHTML={{__html: content.content}} />
            </div>
            <div className="job-expert">
              { mainExpert ?  <Member data={ mainExpert.attributes } /> : expert ? <Member data={ expert.attributes } /> : <></> }
            </div>
          </div>
          {/*<div className="content image-grid">
            <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/jorissen_656148724e.jpeg?updated_at=2023-01-30T04:33:41.482Z)` }}></div>
            <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/cover_02dba5bdd8.jpg?updated_at=2022-12-26T17:44:47.130Z)` }}></div>
          </div>*/}
        </section>


        { (section == 'werken') && (
          <>
          <div className="job-content" style={{textAlign:'center'}}>
            <div className="job-text">
              <h3>Team tactics</h3>
              <span className="description">
                Sinds 2016 staan we bekend als de nieuwste sensatie in de Antwerpse rekruteringswereld. We spelen non-stop Champions League en daar zitten deze 5 team tactics zeker voor iets tussen.
              </span>
            </div>
          </div>
          <div className="process">
            <div className="content">
              <div className="process-row first">
                <div className="process-item">
                  <div className="square ico-ambition"></div>
                  <h4>Toon ambitie.</h4>
                  <span className="description">
                    Want wie zónder zit, valt naast de ploeg. Ambitie is het vuur dat brandt in ieder van ons en de bal aan het rollen houdt binnen onze ploeg. Wij streven ernaar het beste rekruteringskantoor op de markt te zijn. Voor minder komen wij ons bed niet uit – behalve als de Belgen spelen, want dan kijken we met heel de ploeg!
                  </span>
                </div>
                <div className="process-item">
                  <div className="square ico-movement"></div>
                  <h4>Blijf in beweging.</h4>
                  <span className="description">
                    Onze ploeg kent maar één versnelling: de zesde. Op ons HQ gonst het dan ook van de bedrijvigheid. Voor passiviteit is er bij ons geen plaats en snel schakelen is de boodschap. We zijn jong en we willen wat. Gemiddeld 26 jaar jong om precies te zien. Bruisen doen we dan ook als een frisse gin tonic op een zwoele zomeravond. Verschrikkelijk cliché, maar je begrijpt het wel.
                  </span>
                </div>
                <div className="process-item">
                  <div className="square ico-enjoy"></div>
                  <h4>Geniet van ieder moment.</h4>
                  <span className="description">
                    Naast jobs in finance en bouw hebben we bij Jorissen & De Jonck aardig wat expertise opgebouwd in een derde niche: entertainment. Daar matchen we vooral onze eigen collega’s met leuke activiteiten in binnen- en buitenland. Successen worden uitgebreid gevierd, tegenslagen gedeeld en tegelijk ook diep gekoesterd. Je leeft per slot van rekening maar één keer en bij Jorissen & De Jonck is dat aan 200%.
                  </span>
                </div>
              </div>
              <div className="process-row">
                <div className="process-item">
                  <div className="square ico-chances"></div>
                  <h4>Bekijk tegenslagen als kansen.</h4>
                  <span className="description">
                    In ons team alleen maar doorzetters, hét kenmerk van een goede recruiter. Een persoonlijk netwerk opbouwen gaat dan ook altijd gepaard met ups &amp; downs, terwijl het pas rendeert wanneer je het goed weet te onderhouden. Met die Growth Mindset in het achterhoofd schop je het bij Jorissen & De Jonck tot een ware kampioen!
                  </span>
                </div>
                <div className="process-item">
                  <div className="square ico-assertive"></div>
                  <h4>Wees assertief.</h4>
                  <span className="description">
                    Wie bij Jorissen  De Jonck aan de slag kan, heeft talent. Het zijn mensen die in staat zijn om opportuniteiten te zien vóór ze zich voordoen. Individuen die méér zien dan een ander, die durven anticiperen en een spatje proactiviteit belangrijker vinden dan een zee van tijd. Hebben we jou net omschreven? Aarzel dan niet.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="job-content howto-wrapper">
            <div className="job-text">
              <h3>Hoe jij je in de ploeg kan spelen</h3>
              <div className="how-to">
                <div className="howto-item">
                  <div className="index">1</div>
                  <div className="howto-content">
                    <h4>Babbelen</h4>
                    <span className="description">
                      Bellen en babbelen, daar zijn we fan van. Je wordt dan ook in eerste instantie telefonisch gecontacteerd door onze Talent Manager. Zij is een kei in kennismaken en voelt meteen of er een klik kan zijn met onze ploeg.
                    </span>
                  </div>
                </div>
                <div className="howto-item">
                  <div className="index">2</div>
                  <div className="howto-content">
                    <h4>Koffie Drinken</h4>
                    <span className="description">
                      Wanneer je goed gebabbeld hebt, word je uitgenodigd in wat misschien wel je tweede thuis wordt. We zijn namelijk benieuwd wat je van onze koffie vindt en dus drinken we graag een kopje samen. Tussendoor zal er ook nog een interview afgenomen worden. Zo leren wij jou wat beter kennen. Tijdens de gebruikelijke rondleiding kan je bovendien al eens een praatje slaan met je nieuwe beste vrienden.
                    </span>
                  </div>
                </div>
                <div className="howto-item">
                  <div className="index">3</div>
                  <div className="howto-content">
                    <h4>Mandekking spelen</h4>
                    <span className="description">
                      In de derde fase van het rekruteringsproces tonen we jou de actie. Je krijgt van ons enkele opdrachten die je laten zien wat de job precies inhoudt. Aan jou om ons te tonen hoe je het zou aanpakken. Daarnaast speel je even mandekking op één van onze collega’s: je volgt telefoongesprekken nauwlettend mee op en je krijgt uit eerste hand extra informatie over hoe collega’s met dezelfde functie de job precies invullen. Zo krijg je een volledig beeld over onze ploeg en de job.
                    </span>
                  </div>
                </div>
                <div className="howto-item">
                  <div className="index">4</div>
                  <div className="howto-content">
                    <h4>Matchfixen</h4>
                    <span className="description">
                      Tijd om de deal te sluiten. Als wij ons werk goed gedaan hebben, zijn we op dit punt overtuigd dat jij een plek in onze ploeg verdient. We schuiven jou dan ook met veel plezier een contractvoorstel onder de neus. Zodra je het ondertekent, heten we jou met veel toeters en bellen welkom bij De Beste Ploeg van ’t Stad!
                    </span>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          </>
        ) }
        <div className="content content-form">
          <div className="column column-form">
            <ApplyForm title={ `Solliciteer voor ${content.title}`} />
          </div>
        </div>
      </div>
    ) : <Loading />
  );
}

export default JobsDetails;