import React from "react";
import Checkbox from "react-custom-checkbox";

import "./Tickbox.scss";

function Tickbox({options, checked, onChange, value, label}) {
  return (
    <div className="tickbox-wrapper">
      <Checkbox
        onChange={ onChange }
        checked={checked}
        icon={
          <div className="tickbox-checked"></div>
        }
        borderColor="#E1E1E1"
        borderWidth={1}
        borderRadius={6}
        className="tickbox"
        size={20}
        label={ label }
      />
    </div>
  );
}

export default Tickbox;
