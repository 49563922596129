import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import "./Profile.scss";
import CandidateGenerator from './CandidateGenerator';
import IterimGenerator from './IterimGenerator';

const PDFGenerator = () => {
  const [candidatesNL, setCandidatesNL] = useState([]);
  const [candidatesFR, setCandidatesFR] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const correctPassword = '123123'; 

  useEffect(() => {
    let populate = '?populate[0]=*';
        populate = populate+'&populate[1]=recruiter.avatar';
        populate = populate+'&populate[2]=talent';
    fetch(process.env.REACT_APP_STRAPI+"/api/candidates"+populate+'&locale=en')
      .then(res => res.json()).then(content => {
        setCandidatesNL(content.data);
    })
    fetch(process.env.REACT_APP_STRAPI+"/api/candidates"+populate+'&locale=fr-BE')
      .then(res => res.json()).then(content => {
        setCandidatesFR(content.data);
    })
      fetch(process.env.REACT_APP_STRAPI+"/api/documents"+populate+'')
      .then(res => res.json()).then(content => {
        console.log(content);
        setDocuments(content.data);
    })
  }, []);

  const generateProfile = async (data, lang="nl") => {
    const blob = await pdf(<CandidateGenerator content={data} lang={lang} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `candidate-${data.file_id}-${lang}.pdf`;
    link.click();
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }


  const generateInterim = async (data= {}, lang= 'nl') => {
    let talent = chunkArray(data.talent, 3);
    console.log(data, talent);
    let content = data;
    content.talent = talent;
    console.log(content);

    const blob = await pdf(<IterimGenerator content={content} lang={lang} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `interim-${lang}.pdf`;
    link.click();
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsPasswordCorrect(true);
      setWrongPassword(false);
    } else {
      setWrongPassword(true);
    }
  };

  return (
    <div id="profile-wrapper">
      <div style={{ maxWidth: 800, marginTop: 80 }}>
        <div className="profile-box content less-margin column with-background">
          {!isPasswordCorrect && (
            <div className="login-box">
              <h2>Login</h2>
              <form onSubmit={handlePasswordSubmit}>
                <label>Password</label>
                <input type="password" name="password" id="in-password" placeholder="Fill in password to proceed" value={password} onChange={handlePasswordChange} />
                <input type="submit" className="btn-main submit small" value="Submit" />
              </form>
              {wrongPassword && <span className="error-message">Wrong password. Please try again.</span>}
            </div>
          )}
          {isPasswordCorrect && (
            <div className="fiches-box">
              <h2>Profielfiches</h2>
              <h4>Nederlands</h4>
              <table width="100%" cellSpacing="0" style={{ marginBottom: 10 }}>
                {/*<thead>
                  <tr><th align="left">Name</th><th align="left">Link</th></tr>
                </thead>*/}
                <tbody>
                  {
                    candidatesNL.map((value, key)=> {
                      return (
                      <tr key={ key }>
                        <td width="100%"><span style={{ marginRight: 20 }}>{ value.attributes.title }</span></td>
                        <td><button className="btn-main small" onClick={() => generateProfile(value.attributes, 'nl') }>Generate PDF</button></td>
                      </tr>)
                    })
                  }
                </tbody>
              </table>
              <h4>Français</h4>
              <table width="100%" cellSpacing="0" style={{ marginBottom: 30 }}>
                {/*<thead>
                  <tr><th align="left">Name</th><th align="left">Link</th></tr>
                </thead>*/}
                <tbody>
                  {
                    candidatesFR.map((value, key)=> {
                      return (
                      <tr key={ key }>
                        <td width="100%"><span style={{ marginRight: 20 }}>{ value.attributes.title }</span></td>
                        <td><button className="btn-main small" onClick={() => generateProfile(value.attributes, 'fr') }>Generate PDF</button></td>
                      </tr>)
                    })
                  }
                </tbody>
              </table>
              <h2>Interim</h2>
              <table width="100%" cellSpacing="0" style={{ marginBottom: 0 }}>
                <tbody>
                  {
                    documents.map((value, key)=> {
                      return (
                      <tr key={ key }>
                        <td width="100%"><span style={{ marginRight: 20 }}>{ value.attributes.title }</span></td>
                        <td><button className="btn-main small" onClick={() => generateInterim(value.attributes, 'nl') }>Generate PDF</button></td>
                      </tr>)
                    })
                  }
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
