import React from 'react';

const Loading = () => {
  import('./style.scss');
  return(
  	<div className="loading-wrapper">
    	<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading;