import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { getCurrentLanuage, getTxt } from '@data/locales';

const ContactForm = ({ title, section }) => {
  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { name: name, email: email, message: message };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/contact-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_hs7lv2r', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(name == '' || email == '' || message == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
          <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title }</h3>
            <label>{ getTxt('forms', 'full_name') }</label>
            <input type="text" name="name" onChange={ (event) => setName(event.target.value) } />
            <label>{ getTxt('forms', 'email') }</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />
            {
              (section == 'werknemers') && (
                <>
                  <label>{ getTxt('forms', 'phone') }</label>
                  <input type="text" name="phone" onChange={ (event) => setPhone(event.target.value) } />
                </>
              )
            }
            {
              (section == 'werkgevers') && (
                <>
                  <label>{ getTxt('forms', 'company') }</label>
                  <input type="text" name="company" onChange={ (event) => setCompany(event.target.value) } />
                </>
              )
            }
            
            <label>{ getTxt('forms', 'message') }</label>
            <textarea name="messsage" onChange={ (event) => setMessage(event.target.value) } />
            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default ContactForm;