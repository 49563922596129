//libs
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loading from "@components/Loading";

const Terms = () => {
  const [content, setContent] = useState(false);


  useEffect(() => {

    let populate = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/cookie-policy"+populate)
     .then(res => res.json()).then(intro => {
      console.log(intro);
      setContent(intro.data.attributes);
     })
   }, []);

  return (
    content ? (
      <div className="page-wrapper content">
        <h1>{ content.title }</h1>
        <div className="content" dangerouslySetInnerHTML={{__html: content.description}} />
      </div>
    ) : <Loading />
  );
}

export default Terms;