import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Dropdown from 'react-dropdown';
import { getCurrentLanuage, getTxt } from '@data/locales';

const MakeAppointmentWerkenForm = ({ title }) => {
  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [name, setName] = useState('');
  const [interest, setInterest] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const options = [{ value: 'vaste_job', label: getTxt('forms', 'vaste_job') },{ value: 'student_job', label: getTxt('forms', 'student_job') },{ value: 'stage', label: getTxt('forms', 'stage') }];


  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { name: name, interest: interest.label, phone:phone, email: email };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/contact-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_hrsa3n9', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(name == '' || interest == '' || phone == '' || email == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
          <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title }</h3>
            <label>{ getTxt('forms', 'full_name') } *</label>
            <input type="text" name="name" onChange={ (event) => setName(event.target.value) } />

            <label>{ getTxt('forms', 'interest') } *</label>
            <Dropdown options={options} value={interest} onChange={ (event) => { setInterest(event) } } />

            <label>{ getTxt('forms', 'mobile') } *</label>
            <input type="number" name="name" onChange={ (event) => setPhone(event.target.value) } />

            <label>{ getTxt('forms', 'email') } *</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />

            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default MakeAppointmentWerkenForm;