//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import Loading from "@components/Loading";
import moment from 'moment';
import MainButton from "@components/MainButton";
import MakeAppointmentTalentForm from "@components/MakeAppointmentTalentForm";
import MakeAppointmentWerkgeversForm from "@components/MakeAppointmentWerkgeversForm";
import MakeAppointmentWerkenForm from "@components/MakeAppointmentWerkenForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

const Services = ({ section }) => {
  const [content, setContent] = useState(false);
  useEffect(() => {
    let populate = '?populate[0]=services';
        populate = populate+'&populate[1]=services.image';
        populate = populate+'&populate[2]=services.title';
        populate = populate+'&populate[3]=services.description';
        populate = populate+'&populate[4]=image';
        populate = populate+'&populate[5]=services.button&locale='+getCurrentLanuage();
    console.log(process.env.REACT_APP_STRAPI+"/api/"+section+"-onze-service"+populate);
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-onze-service"+populate)
      .then(res => res.json()).then(services => {
        console.log(services);
        setContent(services.data.attributes);
    })
   }, [section]);

  return (
    content ? (
      <div className="aboutus">
        <section className="headline">
          <div className="content">
            <div className="half-text">
              <h1 style={{ backgroundColor: 'transparent', paddingLeft: 0}}><span>{ content.title.split(":")[0] }</span><span>{ content.title.split(":")[1] }</span></h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.description}} />
            </div>
            { content.image.data ? <div className="half-image"  style={{ backgroundImage: `url(${content.image.data.attributes.url})` }}></div> : <></> }
          </div>
        </section>
        {
          content.services.map((service, key) => (
            <section className="less-margin" key={ key }>
              <div className="content">
                {
                  (key%2!==0) ? (
                    <div className={`column-2n`}>
                      <div className="column">
                        <h3 style={{ color: '#FED417' }}>{ service.title }</h3>
                        <p dangerouslySetInnerHTML={{__html: service.description}} />
                      </div>
                      <div className="column">
                        <div className="column-background" style={{ backgroundImage: `url(${service?.image?.data?.attributes?.url})` }}></div>
                      </div>
                    </div>
                  ) : (
                    <div className={`column-2n`}>
                      <div className="column">
                        <div className="column-background" style={{ backgroundImage: `url(${service?.image?.data?.attributes?.url})` }}></div>
                      </div>
                      <div className="column">
                        <h3 style={{ color: '#FED417' }}>{ service.title }</h3>
                        <p dangerouslySetInnerHTML={{__html: service.description}} />
                      </div>
                    </div>
                  )
                }
              </div>
            </section>
          ))
        }
      </div>
    ) : <Loading />
  );
}

export default Services;