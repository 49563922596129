//libs
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Loading from "@components/Loading";
import MainButton from "@components/MainButton";
import moment from 'moment';
import useWindowDimensions from '@data/hooks/useWindowDimensions';
import MakeAppointmentTalentForm from "@components/MakeAppointmentTalentForm";
import MakeAppointmentWerkgeversForm from "@components/MakeAppointmentWerkgeversForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

import "swiper/css";

const Home = ({section}) => {
  const { height, width } = useWindowDimensions();
  const [content, setContent] = useState(false);
  const [blog, setBlog] = useState(false);
  const [niches, setNiches] = useState(false);
  const [currentNiche, setCurrentNiche] = useState(0);
  const [currentSubNiche, setCurrentSubNiche] = useState(0);
  const [options, setOptions] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    let populate = '?populate[0]=header.title';
        populate = populate+'&populate[1]=header.button1';
        populate = populate+'&populate[2]=header.button2';
        populate = populate+'&populate[3]=header.headerImage';
        populate = populate+'&populate[4]=sectie_1';
        populate = populate+'&populate[5]=sliderList';
        populate = populate+'&populate[6]=homeservices';
        populate = populate+'&populate[7]=sliderList.image&locale='+getCurrentLanuage();
    
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-homepage"+populate)
      .then(res => res.json()).then(home => {
        setContent(home.data.attributes);
    })

    let populate_blog = '?populate=*';
        populate_blog = populate_blog+'&pagination[limit]=3&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/blogs"+populate_blog)
      .then(res => res.json()).then(blog => {
        setBlog(blog.data);
    })

    let populate_niches = '?populate=*&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/niches"+populate_niches)
      .then(res => res.json()).then(niches => {
        setNiches(niches.data);
    })
   }, [section]);

  useEffect(() => {
    let _options = [];
    if(niches){
      niches[currentNiche].attributes.niche.forEach((value, key) => {
        _options.push({ label: value.title, value: key })
      })
    }
    setOptions(_options);
    
  }, [niches, currentNiche]);


  return (
    (content && blog) ? (
      <div className="home">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
            {
              section == 'werkgevers' ? <MakeAppointmentWerkgeversForm title={ "Maak een afspraak" } />  : <MakeAppointmentTalentForm title={ "Maak een afspraak" } />
            }
            </div>
          </div>
        ) }
        <div className="carousel" style={{ backgroundImage: `url(${content.header.headerImage.data.attributes.formats.large.url})` }}>
          <div className="content less-margin">
            <h1><span>{ content.header.title.markedTitle }</span><span>{ content.header.title.title }</span></h1>
            <span className="intro-text" dangerouslySetInnerHTML={{__html: content.header.description}} />
            { (content.header.button1 && (section == 'werknemers')) && <MainButton link={ section+(section == 'werkgevers' ? '/kandidaten' : '/jobs') } visible={ content.header.button1.showButton }>{ content.header.button1.text }</MainButton> }
            { content.header.button2 && <a href={'#'} className={ 'btn-main' } onClick={ () => { setPopup(true) } } ><span>{ content.header.button2.text }</span></a>  }
          </div>
        </div>
        <section className="less-margin">
          <div className="content less-margin">
            <h2 className="no-margin">{ content.sectie_1.title }</h2>
            <h3>{ content.sectie_1.subTitle }</h3>
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_1.description.split("///")[0]}} />
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_1.description.split("///")[1]}} />
          </div>
        </section>
        { niches && (
          <section className="less-margin">
            <div className="content">
              {/*<div className="content less-margin column with-background" style={{ float: 'left', paddingBottom: 20, borderRadius: '9px 9px 0 0' }}>
                <h2 style={{ width: '100%', textAlign: 'center' }}>{ getTxt('talentHome', 'servicesTitle') }</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                { content.homeservices.map((value, key) => (
                    <div className="column half" style={{ marginBottom: 40 }}>
                      <h3>{ value.title }</h3>
                      <div dangerouslySetInnerHTML={{__html: value.description }} />
                    </div>
                  )) }
                </div>
              </div>*/}
              <div className="content less-margin column with-background" style={{ float: 'left', paddingBottom: 20, borderRadius: '9px 9px 0 0' }}>
                <h2 style={{ width: '100%', textAlign: 'center' }}>{ getTxt('talentHome', 'nichesTitle') }</h2>
                <div className="column half" dangerouslySetInnerHTML={{__html: content.intro_niches.split("///")[0]}} />
                <div className="column half" dangerouslySetInnerHTML={{__html: content.intro_niches.split("///")[1]}} />
              </div>
              
              <div className="niches-menu" style={{ backgroundColor: 'rgba(255, 255, 255, 0.0509803922)', marginBottom: 0, paddingBottom: 20}}>
                { niches[0] && (
                  <span onClick={ () => { setCurrentSubNiche(0); setCurrentNiche(0) } } className={ `btn-niche btn-construction ${ (currentNiche === 0) ? 'active': '' }` } >
                    { niches[0]?.attributes.nicheTitle }
                  </span>
                )}
                { niches[1] && (
                  <span onClick={ () => { setCurrentSubNiche(0); setCurrentNiche(1) } } className={ `btn-niche btn-engineering  ${ (currentNiche === 1) ? 'active': '' }` } >
                    { niches[1]?.attributes.nicheTitle }
                  </span>
                )}
                { niches[2] && (
                  <span onClick={ () => { setCurrentSubNiche(0); setCurrentNiche(2) } } className={ `btn-niche btn-finance  ${ (currentNiche === 2) ? 'active': '' }` } >
                    { niches[2]?.attributes.nicheTitle }
                  </span>
                )}
              </div>
              <div className="column-2n with-background" style={{ borderRadius: '0 0 9px 9px' }}>
                <div className="column">
                  <h3>{ niches[currentNiche].attributes.nicheTitle }</h3>
                  <h4 style={{ color: '#FED417' }}>{ getTxt('talentHome', 'clientsTitle') }</h4>
                  <Dropdown options={options} onChange={(e) => { setCurrentSubNiche(e.value) }} value={options[0]} placeholder="Select an option" />
                  <div>
                    { (section == 'werknemers') ? <div className="niches-descr" dangerouslySetInnerHTML={{__html: niches[currentNiche].attributes.niche[currentSubNiche].descriptionTalent}} /> : <div className="niches-descr" dangerouslySetInnerHTML={{__html: niches[currentNiche].attributes.niche[currentSubNiche].description}} /> }
                    
                  </div>
                  { (section == 'werknemers') && (<MainButton link={ section+'/'+((section=='werknemers') ? 'jobs' : 'kandidaten') }>{ (section=='werknemers') ? getTxt('talentHome', 'btnJobs') : getTxt('talentHome', 'btnCandidates') }</MainButton>) }
                </div>
                <div className="column">
                  <div className="column-background" style={{ backgroundImage: `url(${niches[currentNiche].attributes.nicheImage.data.attributes.formats.large.url})` }}></div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section>
          <div className="content">
            <div className="column-2n switch">
              <div className="column">
                <div className="column-background" style={{ backgroundImage: `url(${content.sliderList[currentSlide].image?.data?.attributes?.formats?.medium?.url})` }}></div>
              </div>
              <div className="column column-box">
                <Swiper style={{ maxWidth: (width < 960) ? width-80 : 480 }} navigation={true} onSlideChange={(e) => setCurrentSlide(e.activeIndex) }  pagination={true} modules={[Navigation, Pagination]} className="mySwiper">
                  {
                    content.sliderList.map((value, key) => {
                      return (
                        <SwiperSlide key={ key }>
                          <h3>{ value.Title }</h3>
                          <h4 style={{ color: '#FFF' }}>{ value.subline }</h4>
                          <p dangerouslySetInnerHTML={{__html: value.description}} />
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        {/*<section className="blog-section">
          <div className="content">
            <h2><span>Blog</h2>
            <MainButton link={ section+'/blog'} type="line">Alle blog artikelen</MainButton>
            <div className="blog-items">
              {
                blog.map((value, key) => {
                  return (
                    <Link className="btn-blog" to={`/${section}/blog/${value.id}` } key={ key }>
                      <div className="blog-image">
                        <div className="image" style={{ backgroundImage: `url(${value.attributes.heroImage.data.attributes.formats.large.url})` }}></div>
                      </div>
                      <div className="blog-item">
                        <span className="tag">{ value.attributes.category }</span>
                        <span className="date">{ moment(value.attributes.title.updatedAt).format('MMM D, YYYY') }</span>
                        <h4>{ value.attributes.title.substring(0, 50) + '...' }</h4>
                        <hr />
                        <div className="author-block">
                          <div className="author-img" style={{ backgroundImage: 'url(https://picsum.photos/400/600?rand=3' }}></div>
                          <span className="author">Marvin McKinney</span>
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
            </div>
          </div>
        </section>*/}
      </div>
    ) : <Loading />
  );
}

export default Home;