import React from "react";
import { Route, Routes } from "react-router-dom";

/*#####LAYOUTS
########################################*/
import MainLayout from "@components/Layout/Main";
import Intro from "@pages/Intro";
import NotFound from "@pages/NotFound";

/*#####PAGES
########################################*/
import Home from "@pages/Home";
import HomeWerken from "@pages/HomeWerken";
import AboutUs from "@pages/AboutUs";
import Jobs from "@pages/Jobs";
import Candidates from "@pages/Candidates";
import CandidatesDetails from "@pages/Candidates/details";
import JobsDetails from "@pages/Jobs/details";
import Team from "@pages/Team";
import Contact from "@pages/Contact";
import Blog from "@pages/Blog";
import BlogDetails from "@pages/Blog/details";
import Positions from "@pages/Positions";
import Services from "@pages/Services";
import PositionsDetails from "@pages/Positions/details";
import Terms from "@pages/Legal/Terms";
import Privacy from "@pages/Legal/Privacy";
import Cookies from "@pages/Legal/Cookies";
import Profile from "@pages/Profile";

const RenderLayout = (layout, element, page="main") => {
  return (layout === 'intro' ? <>{ element }</> : <MainLayout page={page} section={layout}>{ element }</MainLayout>)
}

function App() {
  return (
    <Routes>
      <Route exact path={'/'} element={RenderLayout('intro', <Intro />)}/>
      <Route exact path={'/werknemers'} element={RenderLayout('werknemers', <Home section="werknemers" />, 'home')}/>
      <Route exact path={'/werknemers/over-ons'} element={RenderLayout('werknemers', <AboutUs section="werknemers" />, 'aboutus')}/>
      <Route exact path={'/werknemers/jobs'} element={RenderLayout('werknemers', <Jobs section="werknemers" />, 'jobs')}/>
      <Route exact path={'/werknemers/jobs/:id'} element={RenderLayout('werknemers', <JobsDetails section="werknemers" />, 'jobs')}/>
      <Route exact path={'/werknemers/onze-ploeg'} element={RenderLayout('werknemers', <Team section="werknemers" />, 'team')}/>
      <Route exact path={'/werknemers/contact'} element={RenderLayout('werknemers', <Contact section="werknemers" />, 'contact')}/>
      <Route exact path={'/werknemers/blog'} element={RenderLayout('werknemers', <Blog section="werknemers" />, 'blog')}/>
      <Route exact path={'/werknemers/blog/:id'} element={RenderLayout('werknemers', <BlogDetails section="werknemers" />, 'blog')}/>
      <Route exact path={'/werknemers/services'} element={RenderLayout('werknemers', <Services section="werknemers" />, 'services')}/>

      <Route exact path={'/werkgevers'} element={RenderLayout('werkgevers', <Home section="werkgevers" />, 'home')}/>
      <Route exact path={'/werkgevers/over-ons'} element={RenderLayout('werkgevers', <AboutUs section="werkgevers" />, 'aboutus')}/>
      <Route exact path={'/werkgevers/kandidaten'} element={RenderLayout('werkgevers', <Candidates section="werkgevers" />, 'candidates')}/>
      <Route exact path={'/werkgevers/kandidaten/:id'} element={RenderLayout('werkgevers', <CandidatesDetails section="werkgevers" />, 'candidates')}/>
      <Route exact path={'/werkgevers/onze-ploeg'} element={RenderLayout('werkgevers', <Team section="werkgevers" />, 'team')}/>
      <Route exact path={'/werkgevers/contact'} element={RenderLayout('werkgevers', <Contact section="werkgevers" />, 'contact')}/>
      <Route exact path={'/werkgevers/blog'} element={RenderLayout('werkgevers', <Blog section="werkgevers" />, 'blog')}/>
      <Route exact path={'/werkgevers/blog/:id'} element={RenderLayout('werkgevers', <BlogDetails section="werkgevers" />, 'blog')}/>
      <Route exact path={'/werkgevers/services'} element={RenderLayout('werkgevers', <Services section="werkgevers" />, 'services')}/>

      <Route exact path={'/werken'} element={RenderLayout('werken', <HomeWerken section="werken" />, 'home')}/>
      <Route exact path={'/werken/over-ons'} element={RenderLayout('werken', <AboutUs section="werken" />, 'aboutus')}/>
      
      <Route exact path={'/werken/contact'} element={RenderLayout('werken', <Contact section="werken" />, 'contact')}/>
      <Route exact path={'/werken/services'} element={RenderLayout('werken', <Services section="werken" />, 'services')}/>
      <Route exact path={'/werken/onze-ploeg'} element={RenderLayout('werken', <Team section="werken" />, 'team')}/>
      <Route exact path={'/werken/solliciteer-nu'} element={RenderLayout('werken', <Positions section="werken" />, 'apply')}/>
      <Route exact path={'/werken/solliciteer-nu/:id'} element={RenderLayout('werken', <PositionsDetails section="werken" />, 'apply')}/>
      <Route exact path={'/terms-and-conditions'} element={RenderLayout('werkgevers', <Terms section="legal" />, '')}/>
      <Route exact path={'/privacy-policy'} element={RenderLayout('werkgevers', <Privacy section="legal" />, '')}/>
      <Route exact path={'/cookies'} element={RenderLayout('werkgevers', <Cookies section="legal" />, '')}/>
      <Route exact path={'/document-generator'} element={RenderLayout('intro', <Profile />)}/>

      <Route path='*' element={RenderLayout('intro', <NotFound />)}/>
    </Routes>
  );
}

export default App;
