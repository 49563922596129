import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from '@assets/images/logo.svg';
import { getTxt } from '@data/locales';

const Footer =({children, page}) => {
  import('./style.scss');
  return(
    <footer>
		<div className="content">
			<div className="footer-items">
				<div className="footer-privacy">
					<img className="logo" alt="Jorissen & De Jonck" src={ logo } width="100%" /><br />
				</div>
				<div className="footer-links">
					<h4>{ getTxt('mainMenu', 'talent') }</h4>
					<Link className={ (page == 'werknemers-aboutus') ? 'active' : '' } to="/werknemers/over-ons">{ getTxt('mainMenu', 'aboutus') }</Link>
					<Link className={ (page == 'werknemers-jobs') ? 'active' : '' } to="/werknemers/jobs">{ getTxt('mainMenu', 'jobs') }</Link>
					<Link className={ (page == 'werknemers-team') ? 'active' : '' } to="/werknemers/onze-ploeg">{ getTxt('mainMenu', 'team') }</Link>
					<Link className={ (page == 'werknemers-contact') ? 'active' : '' } to="/werknemers/contact">{ getTxt('mainMenu', 'contact') }</Link>
				</div>
				<div className="footer-links">
					<h4>{ getTxt('mainMenu', 'employers') }</h4>
					<Link className={ (page == 'werkgevers-aboutus') ? 'active' : '' } to="/werkgevers/over-ons">{ getTxt('mainMenu', 'aboutus') }</Link>
					{/*<Link className={ (page == 'werkgevers-candidates') ? 'active' : '' } to="/werkgevers/kandidaten">Kandidaten</Link>*/}
					<Link className={ (page == 'werkgevers-team') ? 'active' : '' } to="/werkgevers/onze-ploeg">{ getTxt('mainMenu', 'team') }</Link>
					<Link className={ (page == 'werkgevers-contact') ? 'active' : '' } to="/werkgevers/contact">{ getTxt('mainMenu', 'contact') }</Link>
				</div>
				<div className="footer-links">
					<h4>{ getTxt('mainMenu', 'jdj') }</h4>
					<Link className={ (page == 'werken-aboutus') ? 'active' : '' } to="/werken/over-ons">{ getTxt('mainMenu', 'aboutus') }</Link>
					<Link className={ (page == 'werken-apply') ? 'active' : '' } to="/werken/solliciteer-nu">{ getTxt('mainMenu', 'vacancies') }</Link>
					<Link className={ (page == 'werken-team') ? 'active' : '' } to="/werken/onze-ploeg">{ getTxt('mainMenu', 'team') }</Link>
					<Link className={ (page == 'werken-contact') ? 'active' : '' } to="/werken/contact">{ getTxt('mainMenu', 'contact') }</Link>
				</div>
				<div className="footer-links footer-contact">
					<h4>{ getTxt('mainMenu', 'contact') }</h4>
					<div>
						<p><strong>Jorissen & De Jonck BVBA</strong><br />
						Uitbreidingstraat 86,<br />
						2600 Antwerpen</p>
						<p>
							<a href="tel:032465770">03/246.57.70</a><br />
							<a href="mailto:hallo@jorissendejonck.be">hallo@jorissendejonck.be</a>
						</p>
					</div>
				</div>
			</div>
			<div style={{ width: '100%', fontSize: 10, textAlign: 'center' }}>
				<span>
					© 2022 Jorissen & De Jonck - 
					<Link className={ (page == 'terms') ? 'active' : '' } to="/terms-and-conditions">Terms & Conditions</Link> - 
					<Link className={ (page == 'privacy') ? 'active' : '' } to="/privacy-policy">Privacy Policy</Link> - 
					<Link className={ (page == 'cookies') ? 'active' : '' } to="/cookies">Cookies</Link>
				</span>
			</div>
		</div>
	</footer>
  )
}

export default Footer;