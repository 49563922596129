// Import React and necessary components from @react-pdf/renderer
import React from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import styles from './CandidatesStyle';
import "./Profile.scss";

// Import the local images
import backgroundHeader from './images/background.jpg';
import logo from './images/logo.png';
import fontGeoBold from "./fonts/GeogrotesqueCyr-Bold.ttf";
import fontGeoLight from "./fonts/GeogrotesqueCyr-Light.ttf";

import profileIcon from './images/cv-profile.png';
import regionIcon from './images/cv-region.png';
import educationIcon from './images/cv-education.png';
import experienceIcon from './images/cv-experience.png';
import searchIcon from './images/cv-search.png';
import currentIcon from './images/cv-current.png';

import locationFooter from './images/footer-location.png';
import mailFooter from './images/footer-mail.png';
import phoneFooter from './images/footer-phone.png';
import worldFooter from './images/footer-world.png';

Font.register({
  family: 'Geogrotesque',
  fonts: [
    { src: fontGeoBold, fontWeight: 'bold' },
    { src: fontGeoLight, fontWeight: 'light' }
  ],
});

const ProfileRow = ({ iconSrc, labelText, content }) => (
  <View style={styles.profileRow}>
    <View style={styles.iconWrapper}>
      <Image src={iconSrc} style={styles.icon} />
    </View>
    <View style={styles.labelWrapper}>
      <Text style={styles.labelText}>{labelText}</Text>
    </View>
    <View style={styles.lineWrapper}>
      <View style={styles.line} />
    </View>
    <View style={styles.detailsWrapper}>
      <Text>{content}</Text>
    </View>
  </View>
);

const CandidateGenerator = ({ content, lang = "nl" }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={backgroundHeader} style={styles.backgroundImage} />
          <Image src={logo} style={styles.logo} />
          <View style={styles.headerinfo}>
            <View style={styles.headerinfoId}>
              <Text>ID {content.file_id}</Text>
            </View>
            <View style={styles.headerinfoTitle}>
              <Text style={styles.headerinfoTitleText}>{content.title.toUpperCase() || 'Voorstelling kandidaat'}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <ProfileRow iconSrc={profileIcon} labelText={ (lang=='nl') ? 'PROFIEL' : 'PROFIL' } content={content.profile} />
          <ProfileRow iconSrc={regionIcon} labelText={ (lang=='nl') ? 'REGIO' : 'RÉGION' } content={content.region} />
          <ProfileRow iconSrc={educationIcon} labelText={ (lang=='nl') ? 'OPLEIDING' : 'ÉDUCATION' } content={content.education} />
          <ProfileRow iconSrc={experienceIcon} labelText={ (lang=='nl') ? 'ERVARING' : 'EXPÉRIENCE' } content={content.experience} />
          <ProfileRow iconSrc={searchIcon} labelText={ (lang=='nl') ? 'OP ZOEK NAAR' : 'À LA RECHERCHE DE' } content={content.in_search_of} />
          <ProfileRow iconSrc={currentIcon} labelText={ (lang=='nl') ? 'HUIDIGE VERLONING' : 'SALAIRE ACTUEL' } content={content.current_situation} />
        </View>

        <View style={styles.teamMember}>
          <Image src={backgroundHeader} style={styles.backgroundImage} />
          <View style={styles.circleWrapper}>
            <View style={styles.circleInner}>
              <Image src={content.recruiter.data.attributes.avatar.data.attributes.formats.medium.url} style={styles.circleBackground} />
            </View>
          </View>
          <View style={styles.memberDetails}>
            <Text style={styles.memberDetailsName}>{content.recruiter.data.attributes.name}</Text>
            <Text style={styles.memberDetailsFunction}>{content.recruiter.data.attributes.functionTitle}</Text>
            <Text style={styles.memberDetailsEmail}>{content.recruiter.data.attributes.email}</Text>
          </View>
          <View style={styles.memberLine} />
          <View style={styles.memberBio}>
            <Text style={styles.memberBioText}>{content.recruiter.data.attributes.bio}</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <View style={styles.footerBlock}>
            <Image src={mailFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>hallo@jorissendejonck.be</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={phoneFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>03 246 57 70</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={locationFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>Uitbreidingstraat 86, 2600 Antwerpen</Text>
          </View>
          <View style={styles.footerBlock}>
            <Image src={worldFooter} style={styles.iconFooter} />
            <Text style={styles.footerText}>jorissenendejonck.be</Text>
          </View>
        </View>

      </Page>
    </Document>
  );
};

export default CandidateGenerator;
