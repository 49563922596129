//libs
import { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import Loading from "@components/Loading";
import Member from "@components/Member";
import MainButton from "@components/MainButton";
import rocket from '@assets/images/rocket-2.png';
import MakeAppointmentWerkgeversForm from "@components/MakeAppointmentWerkgeversForm";
import MakeAppointmentWerkenForm from "@components/MakeAppointmentWerkenForm";
import MakeAppointmentTalentForm from "@components/MakeAppointmentTalentForm";
import _ from 'underscore';
import { HashLink as Link } from 'react-router-hash-link';
import { getCurrentLanuage, getTxt } from '@data/locales';

const WerknemersJobs = ({ section }) => {
  const [content, setContent] = useState(false);
  const [popup, setPopup] = useState(false);
  const [categories, setCategories] = useState(false);

  useEffect(() => {
    let populate = '?populate=*&locale='+getCurrentLanuage()+'&pagination[pageSize]=200';
    let page_data = {};
    let _categories = [];
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-onze-ploeg"+populate)
     .then(res => res.json()).then(intro => {
        fetch(process.env.REACT_APP_STRAPI+"/api/teams"+populate+"&sort=sort")
        .then(res => res.json()).then(team => {
          page_data.intro = (section=='werken') ? intro.data.attributes.sectien : intro.data.attributes.section;
          page_data.team = team.data;
          team.data.map((value, key) => {
            _categories.push(value.attributes.fieldFunction);
          })
          _categories = _.uniq(_categories, false);
          setCategories(['Finance', 'Construction', 'Engineering', 'Interim management & Consulting', 'Backoffice', 'Founders']); 
          setContent(page_data);
        })
     })
   }, [section]);

  const slugify = (str) => {
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }

  return (
    content && categories ? (
      <div className="team">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
              { (section == 'werkgevers') ? (
                <MakeAppointmentWerkgeversForm title={ getTxt('team', 'makeAppointment') } />
              ) : (section == 'werknemers') ? <MakeAppointmentTalentForm title={ getTxt('team', 'makeAppointment') } /> : <MakeAppointmentWerkenForm title={ getTxt('team', 'makeAppointment') } />
              }
            </div>
          </div>
        ) }
        <section className="headline less-margin">
          <div className="content">
            <div className="half-text">
              <h1><span>{ content.intro.title.split(":")[0] }</span><span>{ content.intro.title.split(":")[1] }</span></h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.intro.description }} />
            </div>
          </div>
        </section>
        <div className="content">
          {/*<div className="jobs-filters-wrapper">
            <h4 style={{ marginTop: 20, color: "#FED417" }}>Functie</h4>
            <div className="jobs-filters">
              <div className="filter">
                <ul className="list-niches">
                  {
                    categories.map((value, key) => {
                      return (
                        <Link to={'#'+slugify(value)} className="tag transparent">{ value }</Link>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          */}
          <section className="team-section-wrapper">
            {
              categories.map((value, key) => {
                return (
                  <div className="category" style={{ marginBottom : 50 }} id={slugify(value)}>
                    <h3 style={{ color: '#FED417' }}>{ getTxt('team', value.replace(/ /g,'').replace('&', '').toLowerCase() )}</h3>
                    <div className="team-section">
                      {
                        content.team.filter((team_members) => { return team_members.attributes.fieldFunction == value }).map((member, key) => {
                          return (
                            <Member key={key} data={ member.attributes } />
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
            {/*
              content.team.map((value, key) => {
                return (
                  <Member key={key} data={ value.attributes } />
                )
              })
            */}
          </section>
        </div>
        { (section == 'werkgevers') ? (
          <section className="less-margin">
            <div className="content">
              <div className="column-2n with-background" style={{ position: 'relative' }}>
                <div className="column">
                  <h4>{ getTxt('team', 'workWithUs') }</h4>
                  <h2 style={{ marginBottom: 15 }}>{ getTxt('team', 'launchyour') } <br /><i style={{ color: '#FED417', fontStyle: 'normal' }}>{ getTxt('team', 'vacature') }</i> { getTxt('team', 'here') }</h2>
                  <a href={'#'} className={ 'btn-main' } onClick={ () => { setPopup(true) } } ><span>{ getTxt('team', 'launch') }</span></a>
                </div>
                <div className="column" style={{ maxHeight: 300 }}>
                  <img className="logo-1 rocket" alt="Jorissen & De Jonck" src={ rocket } width="100%" />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="less-margin">
            <div className="content">
              <div className="column-2n with-background" style={{ position: 'relative' }}>
                <div className="column">
                  <h4>{ getTxt('team', 'workHere') }</h4>
                  <h2 style={{ marginBottom: 15 }}>{ getTxt('team', 'boost') } <br /><i style={{ color: '#FED417', fontStyle: 'normal' }}>{ getTxt('team', 'carriere') }</i> { getTxt('team', 'here') }</h2>
                  <a href={'#'} className={ 'btn-main' } onClick={ () => { setPopup(true) } } ><span>{ getTxt('team', 'makeAppointment') }</span></a>
                </div>
                <div className="column" style={{ maxHeight: 300 }}>
                  <img className="logo-1 rocket" alt="Jorissen & De Jonck" src={ rocket } width="100%" />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    ) : <Loading />
  );
}

export default WerknemersJobs;