import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { getCurrentLanuage, getTxt } from '@data/locales';

const ApplyForm = ({ title }) => {

  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [link, setLink] = useState('');
  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let _title = title ? title : 'Spontane sollicitatie';
      let data = { firstName: firstName, lastName: lastName, email: email, phone: phone, submission: _title };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/apply-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_aj5rn6u', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(firstName == '' || lastName == '' || email == '' || phone == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
         <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title ? title : 'Spontane sollicitatie' }</h3>
            <label>{ getTxt('forms', 'firstName') } *</label>
            <input type="text" name="firstName" onChange={ (event) => { console.log(event); setFirstName(event.target.value) } } />
            <label>{ getTxt('forms', 'lastName') } *</label>
            <input type="text" name="lastName" onChange={ (event) => setLastName(event.target.value) } />
            <label>{ getTxt('forms', 'email') } *</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />
            <label>{ getTxt('forms', 'phone') } *</label>
            <input type="text" name="phone" onChange={ (event) => setPhone(event.target.value) } />
            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default ApplyForm;