//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import Loading from "@components/Loading";
import moment from 'moment';
import MainButton from "@components/MainButton";
import MakeAppointmentTalentForm from "@components/MakeAppointmentTalentForm";
import MakeAppointmentWerkgeversForm from "@components/MakeAppointmentWerkgeversForm";
import MakeAppointmentWerkenForm from "@components/MakeAppointmentWerkenForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

const AboutUs = ({ section }) => {
  const [popup, setPopup] = useState(false);
  const [content, setContent] = useState(false);
  useEffect(() => {
    let populate = '?populate[0]=section_1';
        populate = populate+'&populate[1]=section_2';
        populate = populate+'&populate[2]=section_3';
        populate = populate+'&populate[3]=section_4';
        populate = populate+'&populate[4]=section_5';
        populate = populate+'&populate[5]=section_6';
        populate = populate+'&populate[6]=dna';
        populate = populate+'&populate[7]=dna.dnaList';
        populate = populate+'&populate[8]=header';
        populate = populate+'&populate[9]=section_2.image';
        populate = populate+'&populate[10]=section_2.button';
        populate = populate+'&populate[11]=section_3.image';
        populate = populate+'&populate[12]=section_3.button';
        populate = populate+'&populate[13]=section_5.image';
        populate = populate+'&populate[14]=section_5.button';
        populate = populate+'&populate[15]=section_6.image';
        populate = populate+'&populate[16]=section_6.button';
        populate = populate+'&populate[17]=header.image';
        populate = populate+'&populate[18]=header.button';
        populate = populate+'&populate[19]=section_4.button&locale='+getCurrentLanuage();
    console.log(process.env.REACT_APP_STRAPI+"/api/"+section+"-over-ons"+populate);
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-over-ons"+populate)
      .then(res => res.json()).then(about => {
        console.log(about);
        setContent(about.data.attributes);
    })
   }, [section]);

  return (
    content ? (
      <div className="aboutus">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
              {
                section == 'werkgevers' ? <MakeAppointmentWerkgeversForm title={ "Maak een afspraak" } />  : section == 'werken' ? <MakeAppointmentWerkenForm title={ "Maak een afspraak" } /> : <MakeAppointmentTalentForm title={ "Maak een afspraak" } />
              }
            </div>
          </div>
        ) }
        <section className="headline">
          <div className="content">
            <div className="half-text">
              <h1 style={{ backgroundColor: 'transparent', paddingLeft: 0}}><span>{ content.header.title.split(":")[0] }</span><span>{ content.header.title.split(":")[1] }</span></h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.header.description}} />
              {
                content.header.button && <MainButton link={ content.header.button.link } visible={ content.header.button && content.header.button.showButton }>{ content.header.button.text }</MainButton>
              }
            </div>
            { content.header.image.data ? <div className="half-image"  style={{ backgroundImage: `url(${content.header.image.data.attributes.url})` }}></div> : <></> }
          </div>
        </section>
        <section className="less-margin">
          <div className="content">
            {
              (section == 'werken') ? (<h2><span>{ content.section_1.title.split(':')[0] }</span><span>{ content.section_1.title.split(':')[1] }</span></h2>) : (<h2>{ content.section_1.title.split(':')[0] }{ content.section_1.title.split(':')[1] }</h2>)
            }
            
            <h3>{ content.section_1.subTitle }</h3>
            <div className="column half" dangerouslySetInnerHTML={{__html: content.section_1.description.split("///")[0]}} />
            <div className="column half" dangerouslySetInnerHTML={{__html: content.section_1.description.split("///")[1]}} />
          </div>
        </section>
        <section className="less-margin">
          <div className="content">
            <div className="column-2n with-background">
              <div className="column">
                <div className="column-background" style={{ backgroundImage: `url(${content.section_2.image.data.attributes.url})` }}></div>
              </div>
              <div className="column">
                <h3 style={{ color: '#FED417' }}>{ content.section_2.title }{ content.section_2.Title }</h3>
                <p dangerouslySetInnerHTML={{__html: content.section_2.description}} />
                { content.section_2.button && <a href={'#'} className={ 'btn-main' } onClick={ () => { setPopup(true) } } ><span>{ content.section_2.button.text }</span></a> }
              </div>
            </div>
          </div>
        </section>
        {
          (section == 'werken') && (
            <section>
              <div className="content">
                <div className="column-2n switch">
                  <div className="column">
                    <h3 style={{ color: '#FED417' }}>{ content.section_3.title }{ content.section_3.Title }</h3>
                    <p dangerouslySetInnerHTML={{__html: content.section_3.description}} />
                  </div>
                  <div className="column">
                    <div className="column-background" style={{ backgroundImage: `url(${content.section_3.image.data.attributes.url})` }}></div>
                  </div>
                </div>
              </div>
            </section>
          ) }
       {/* <section className="center with-background">
          <h3>{ content.section_4.title }</h3>
          <p dangerouslySetInnerHTML={{__html: content.section_4.description}} />
          { content.section_4.button && <MainButton link={ content.section_4.button.link } visible={ content.section_4.button.showButton }>{ content.section_4.button.text }</MainButton> }
        </section>*/}
        {
          (section != 'werken') && (
            <section className="less-margin">
              <div className="content">
                <div className="column-2n">
                  
                  <div className="column">
                    <h3 style={{ color: '#FED417' }}>{ content.section_5.title }{ content.section_5.Title }</h3>
                    <p dangerouslySetInnerHTML={{__html: content.section_5.description}} />
                    { content.section_5.button && <MainButton link={ content.section_5.button.link } visible={ content.section_5.button.showButton }>{ content.section_5.button.text }</MainButton> }
                  </div>
                  <div className="column">
                    {
                     content.section_5.image.data && <div className="column-background" style={{ backgroundImage: `url(${content.section_5.image.data.attributes.url})` }}></div>
                    }
                  </div>
                </div>
              </div>
            </section>
          )
        }

        <section className="less-margin">
          <div className="content">
            <div className="column-2n switch">
              <div className="column">
                <div className="column-background" style={{ backgroundImage: `url(${content.section_6?.image?.data?.attributes?.url})` }}></div>
              </div>
              <div className="column">
                <h3 style={{ color: '#FED417' }}>{ content.section_6.title }{ content.section_6.Title }</h3>
                <p dangerouslySetInnerHTML={{__html: content.section_6.description}} />
                { content.section_6.button && <MainButton link={ content.section_6.button.link } visible={ content.section_6.button.showButton }>{ content.section_6.button.text }</MainButton> }
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="content">
            <div className="column-2n">
              <div className="column">
                <h2><span style={{ color: ((section == 'werken') ? '#08182E' : '#FFF'), backgroundColor: ((section == 'werken') ? '#FFF' : '#ffffff0d') }}>{ getTxt('aboutUs', 'our') }{ /*content.dna.title*/ }</span><span style={{ color: ((section == 'werken') ? '#08182E' : '#08182E'), background: ((section == 'werken') ? '#FED417' : '#fed416') }}>{ getTxt('aboutUs', 'dna') }</span></h2>
                <p dangerouslySetInnerHTML={{__html: content.dna.description}} />
              </div>
              <div className="column column-box">
                <Swiper navigation={true}  pagination={true} modules={[Navigation, Pagination]} className="mySwiper">
                  { content.dna.dnaList.map((value, key) => {
                      return (
                        <SwiperSlide key={ key }>
                          <h3 style={{ marginBottom: 15 }}  style={{ color: '#FFF' }}><span style={{ color: '#FED417' }}>{ value.title.substring(0, 1) }</span>{ value.title.substring(1, 500) }</h3>
                          <p dangerouslySetInnerHTML={{__html: value.description}} />
                        </SwiperSlide>
                      )
                    }) 
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </section>

      </div>
    ) : <Loading />
  );
}

export default AboutUs;