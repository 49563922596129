//libs
import { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import Loading from "@components/Loading";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "@components/MainButton";
import rocket from '@assets/images/rocket-2.png';
import MakeAppointmentWerkenForm from "@components/MakeAppointmentWerkenForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

const Positions = ({ section }) => {
  const [content, setContent] = useState(false);
  const [positions, setPositions] = useState(false);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    let populate = '?populate=*&locale='+getCurrentLanuage();
    let page_data = {};
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-vacature"+populate)
     .then(res => res.json()).then(intro => {
        fetch(process.env.REACT_APP_STRAPI+"/api/teams"+populate)
        .then(res => res.json()).then(team => {
          console.log(intro);
          page_data.intro = (section=='werken') ? intro.data.attributes : intro.data.attributes.section;
          page_data.team = team.data;
          setContent(page_data);
        })
     })
     let populate_pos = '?populate=*&locale='+getCurrentLanuage();
      fetch(process.env.REACT_APP_STRAPI+"/api/open-posities"+populate_pos)
        .then(res => res.json()).then(content => {
          setPositions(content.data);
      })
   }, [section]);

  return (
    content ? (
      <div className="team">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
              <MakeAppointmentWerkenForm title={ "Maak een afspraak" } />
            </div>
          </div>
        ) }
        <section className="headline less-margin">
          <div className="content">
            <div className="half-text">
              <h1>{ content.intro.title }</h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.intro.description}} />
            </div>
          </div>
        </section>
        <div className="content">
          { positions && (
            <section className="less-margin page-positions">
              <div className="content">
                <h2 className="no-margin">{ getTxt('positions', 'open') }</h2>
                <h3>{ getTxt('positions', 'openTxt') }</h3>
                <div className="job-section">
                  <div className="job-jobs">
                    {
                      positions.map((value, key) => {
                        return (
                          <article className="job" key={ key }>
                            <h4>{ value.attributes.title }</h4>
                            <span className="description">
                              { value.attributes.introText }
                            </span>
                            <div className="subline">
                              <span className="location"><FeatherIcon icon="clock" size="14" /> { value.attributes.period }</span>
                              <Link className="btn-watch" to={`/${section}/solliciteer-nu/${value.id}` }>
                                { getTxt('positions', 'watch') } <FeatherIcon icon="arrow-right" />
                              </Link>
                            </div>
                          </article>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        <section className="less-margin">
          <div className="content">
            <div className="column-2n with-background"  style={{ position: 'relative' }}>
              <div className="column">
                <h4>{ getTxt('positions', 'come') }</h4>
                <h2 style={{ marginBottom: 15 }}>{ getTxt('positions', 'launch') } <br /><i style={{ color: '#FED417', fontStyle: 'normal' }}>{ getTxt('positions', 'carriere') }</i> { getTxt('positions', 'here') }</h2>
                <a href="#" onClick={ () => setPopup(true) } className="btn-main">{ getTxt('positions', 'btnAppointment') }</a>
              </div>
              <div className="column" style={{ maxHeight: 300 }}>
                <img className="logo-1 rocket" alt="Jorissen & De Jonck" src={ rocket } width="100%" />
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : <Loading />
  );
}

export default Positions;