//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Loading from "@components/Loading";

const BlogDetails = ({section}) => {

  const [content, setContent] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    let populate_blog = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/blogs/"+id+populate_blog)
      .then(res => res.json()).then(blog => {
        console.log(blog);
        setContent(blog.data.attributes);
    })
   }, [section]);

  return (
    content ? (
      <div className="blog-details">
        <section className="less-margin">
          <div className="content">
            <h2>{ content.title }</h2>
            <span className="tag">{ content.category }</span><span className="tag transparent">{ moment(content.updatedAt).format('MMM D, YYYY - hh:mm') }</span>
           {/* <div className="author">
              <div className="author-image" ></div>
              <div className="author-details">
                <span className="author-job">Author</span>
                <span className="author-name">Albert Flores</span>
              </div>
            </div>*/}
          </div>
          <div className="blog-image" style={{ backgroundImage: `url(${content.heroImage.data.attributes.formats.large.url})` }}></div>
          <div className="content">
            <div dangerouslySetInnerHTML={{__html: content.content}} />
          </div>
        </section>
      </div>
    ) : <Loading />
  );
}

export default BlogDetails;