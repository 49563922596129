import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 0
  },

  headerPage: {
    flexDirection: 'column', alignItems: 'center', 
    marginBottom: 40,
  },
  backgroundImageHeader: {
    position: 'absolute', top: 0, left: 0, width: '100%', 
    height: '100%', zIndex: -1,
  },
  logoHeader: {
    width: 250, height: 80, margin: 20, marginBottom: 30, 
    marginTop: 30
  },


  fullBackground: {
    position: 'absolute', top: 0, left: 0, right: 0,
    bottom: 0,
  },
  logoWrapper: {
    flexDirection: 'column', alignItems: 'center', marginBottom: 40,
  },
  logo: {
    width: 250, height: 80, margin: 20, 
    marginBottom: 30, marginTop: 60
  },
  headerinfoFront: {
    flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
    width: '100%', paddingHorizontal: 40, flex: 1, paddingBottom: 280
  },
  headerFrontFirst: {
    fontFamily: 'Geogrotesque', fontWeight: 'bold', 
    backgroundColor: '#FED417', fontSize: 40, paddingHorizontal: 10, 
    color: '#08182E', alignSelf: 'flex-start'
  },
  headerFrontSecondWrapper: {
    alignSelf: 'flex-end', marginRight: 60, position: 'relative'
  },
  headerFrontSecond: {
    fontFamily: 'Geogrotesque', fontWeight: 'light', color: '#FED417', 
    fontSize: 36, paddingHorizontal: 15, backgroundColor: '#08182E', 
    alignSelf: 'flex-end'
  },
  headerFrontSecondLines: {
    position: 'absolute', right: -50, width: 60, height: 56,
    top: 20
  },

  logoWrapper: {
    flexDirection: 'column', alignItems: 'center', marginBottom: 40,
  },
  logo: {
    width: 250, height: 80, margin: 20, 
    marginBottom: 30, marginTop: 60
  },
  headerinfoBack: {
    flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
    width: '100%', paddingHorizontal: 20, flex: 1, paddingBottom: 280
  },
  headerBackFirst: {
    fontFamily: 'Geogrotesque', fontWeight: 'bold', 
    backgroundColor: '#FED417', fontSize: 20, paddingHorizontal: 10, 
    color: '#08182E', marginBottom: 2
  },
  headerBackSecond: {
    fontFamily: 'Geogrotesque', fontWeight: 'light', color: '#FED417', 
    fontSize: 20, paddingHorizontal: 15, backgroundColor: '#08182E', 
    marginBottom: 2
  },
  footer: { 
    flexDirection: 'row', position: 'absolute', bottom: 20, 
    left: 40, right: 30, textAlign: 'center', fontSize: 9, 
    fontFamily: 'Geogrotesque', fontWeight: 'light', fontSize: 10,
    color: '#FFF'
  },
  footerBlock: {
    flexDirection: 'row', alignItems: 'center', marginRight: 7
  },
  iconFooter: {
    width: 18, height: 18, marginRight: 2
  },
  profileBoxHeader: {
    alignItems: 'flex-start', paddingLeft: 20, marginBottom: 7
  },
  profileBox: {
    width: '100%', paddingHorizontal: 50, marginBottom: 15,
  },
  profileTitle: {
    fontFamily: 'Geogrotesque', fontWeight: 'bold', backgroundColor: '#FED417', 
    fontSize: 14, paddingHorizontal: 10, color: '#08182E', 
    paddingVertical: 1
  },
  borderBox: {
    borderWidth: 1, borderColor: '#000', padding: 5, borderRadius: 6
  },
  titleLines: {
    position: 'absolute', left: 0, width: 50, height: 25, top: -10
  },
  profileRow: {
    flexDirection: 'row', alignItems: 'center', marginBottom: 2,
  },
  iconWrapper: {
    width: 25, height: 25, marginRight: 0,
  },
  labelWrapper: {
    fontFamily: 'Geogrotesque', fontWeight: 'bold', fontSize: 11, 
    padding: 5, marginRight: 10, flexShrink: 0, 
  },
  lineWrapper: {
    flex: 1, flexDirection: 'row', alignItems: 'center', marginRight: 10,
  },
  line: {
    flex: 1, height: 1, backgroundColor: '#CCC',
  },
  detailsWrapper: {
    width: 330, backgroundColor: 'white', padding: 5, fontFamily: 'Geogrotesque', 
    fontWeight: 'light', fontSize: 10, paddingLeft: 15,
  },
});

export default styles;
