import React from 'react';
import FeatherIcon from 'feather-icons-react';

const Member = ({ data }) => {
  return(
  	<article className="profile" style={{ width: '100%'}}>
      <div className="avatar-wrapper">
        <div className="avatar" style={{ backgroundImage: `url(${data.avatar.data.attributes.formats.small.url})` }}></div>
      </div>
      <h4>{ data.name }</h4>
      <h5>{ data.functionTitle }</h5>
      <div className="socials">
        {
          data.socials.map((social, key) => (
            <a key={key} href={ social.url } className={ `btn-social btn-${social.type}`} target="_blank">
              <FeatherIcon width="17" icon={social.type == 'email' ? 'mail' : social.type }  />
            </a>
          ))
        }
      </div>
    </article>
  )
}

export default Member;