//libs
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Loading from "@components/Loading";
import Member from "@components/Member";
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import MakeAppointmentWerkenForm from "@components/MakeAppointmentWerkenForm";
import MainButton from "@components/MainButton";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import "swiper/css";
import { getCurrentLanuage, getTxt } from '@data/locales';

const HomeWerken = ({section}) => {

  const [content, setContent] = useState(false);
  const [positions, setPositions] = useState(false);
  const [options, setOptions] = useState([]);
  const [team, setTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(0);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [popup, setPopup] = useState(false);
  const [aboutContent, setAboutContent] = useState(false);

  useEffect(() => {
    let populate = '?populate[0]=header.title';
        populate = populate+'&populate[1]=header.button1';
        populate = populate+'&populate[2]=header.button2';
        populate = populate+'&populate[3]=header.headerImage';
        populate = populate+'&populate[4]=sectie_1';
        populate = populate+'&populate[5]=sectie_2';
        populate = populate+'&populate[6]=sectie_3';
        populate = populate+'&populate[7]=sliderList';
        populate = populate+'&populate[8]=sliderList.image&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-homepage"+populate)
      .then(res => res.json()).then(home => {
        setContent(home.data.attributes);
    })
    let populate_pos = '?populate=*&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/open-posities"+populate_pos)
      .then(res => res.json()).then(content => {
        setPositions(content.data);
    })

    let populate_team = '?populate=*&locale='+getCurrentLanuage();
    let page_data = {};
    fetch(process.env.REACT_APP_STRAPI+"/api/teams?populate[0]=*&populate[1]=avatar&populate[2]=socials&filters[showOnWebsite][$eq]=true&locale="+getCurrentLanuage())
        .then(res => res.json()).then(team => {
          console.log(team);
          setTeam(team.data);
     })

    let populate_about = '?populate[0]=section_5';
        populate_about = populate_about+'&populate[13]=section_5.image&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-over-ons"+populate_about)
      .then(res => res.json()).then(about => {
        setAboutContent(about.data.attributes);
    })

   }, [section]);


  return (
    (content) ? (
      <div className="home-werken video">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
              <MakeAppointmentWerkenForm title={ getTxt('workHome', 'applyNow') } />
            </div>
          </div>
        ) }

        <Video autoPlay loop muted
            controls={['PlayPause']}
            poster="http://sourceposter.jpg"
            onCanPlayThrough={() => {}}>
            <source src="/files/video.mp4" type="video/webm" />
        </Video>

        <section className="headline">
          <div className="content">
            <div className="half-text">
              <h1 style={{ backgroundColor: 'transparent' }}><span>{ content.header.title.markedTitle }</span><span>{ content.header.title.title.split(':')[0] }</span></h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.header.description}} />
              <div className="buttons">
                <a href="#"  onClick={ () => { setPopup(true) } } className="btn-main"><span>{ content.header.button1.text }</span></a>
                <a href="/werken/over-ons" style={{ backgroundColor: '#fed416' }} className="btn-main btn-line"><span>{ content.header.button2.text }</span></a>
              </div>
            </div>
            { content.header.headerImage ? <div className="half-image"  style={{ backgroundImage: `url(${content.header.headerImage.data.attributes.url})` }}></div> : <></> }
          </div>
        </section>

        <section className="less-margin">
          <div className="content">
            <h2 className="no-margin"><span>{ content.sectie_1.title.split(':')[0] }</span><span>{ content.sectie_1.title.split(':')[1] }</span></h2>
            <h3>{ content.sectie_1.subTitle }</h3>
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_1.description.split("///")[0]}} />
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_1.description.split("///")[1]}} />
          </div>
        </section>

        <section className="less-margin ">
          <div className="content">
            <h2 className="no-margin"><span>{ content.sectie_2.title.split(':')[0] }</span><span>{ content.sectie_2.title.split(':')[1] }</span></h2>
            <h3>{ content.sectie_2.subTitle }</h3>
            <section className="with-background" style={{ textAlign: 'left', padding: 40, display: 'inherit', borderRadius: 9 }}>
              <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_2.description.split("///")[0]}} />
              <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_2.description.split("///")[1]}} />
            </section>
          </div>
        </section>

        { aboutContent && (
          <section className="less-margin">
              <div className="content">
                <div className="column-2n">
                  
                  <div className="column">
                    <h3 style={{ color: '#FED417' }}>{ aboutContent.section_5.title }{ aboutContent.section_5.Title }</h3>
                    <p dangerouslySetInnerHTML={{__html: aboutContent.section_5.description}} />
                    { aboutContent.section_5.button && <MainButton link={ aboutContent.section_5.button.link } visible={ aboutContent.section_5.button.showButton }>{ aboutContent.section_5.button.text }</MainButton> }
                  </div>
                  <div className="column">
                    {
                     aboutContent.section_5.image.data && <div className="column-background" style={{ backgroundImage: `url(${aboutContent.section_5.image.data.attributes.url})` }}></div>
                    }
                  </div>
                </div>
              </div>
            </section>
        )}

        <section className="less-margin">
          <div className="content">
            <h2 className="no-margin"><span>{ content.sectie_3.title.split(':')[0] }</span><span>{ content.sectie_3.title.split(':')[1] }</span></h2>
            <h3>{ content.sectie_3.subTitle }</h3>
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_3.description.split("///")[0]}} />
            <div className="column half" dangerouslySetInnerHTML={{__html: content.sectie_3.description.split("///")[1]}} />
            <br /><br />
          </div>
          <div className="content">
            <div className="column-2n">
              <div className="column">
                <div className="column-background" style={{ backgroundImage: `url(${content.sliderList[currentSlide].image.data.attributes.url})` }}></div>
              </div>
              <div className="column column-box">
                <Swiper navigation={true} onSlideChange={(e) => setCurrentSlide(e.activeIndex) }  pagination={true} modules={[Navigation, Pagination]} className="mySwiper">
                  {
                    content.sliderList.map((value, key) => {
                      return (
                        <SwiperSlide key={ key }>
                          <h3>{ value.Title }</h3>
                          <h4 style={{ color: '#FFF' }}>{ value.subline }</h4>
                          <p dangerouslySetInnerHTML={{__html: value.description}} />
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        
      
        
        { positions && (
          <section className="less-margin page-positions">
            <div className="content">
              <h2 className="no-margin"><span>{ getTxt('workHome', 'open') }</span><span>{ getTxt('workHome', 'positions') }</span></h2>
              <h3>{ getTxt('workHome', 'positionsTxt') }</h3>
              <div className="job-section">
                <div className="job-jobs">
                  {
                    positions.map((value, key) => {
                      return (
                        <article className="job" key={ key }>
                          <h4>{ value.attributes.title }</h4>
                          <span className="description" dangerouslySetInnerHTML={{__html: value.attributes.introText}} />
                          <div className="subline">
                            <span className="location"><FeatherIcon icon="clock" size="14" /> { value.attributes.period }</span>
                            <Link className="btn-watch" to={`/${section}/solliciteer-nu/${value.id}` }>
                              { getTxt('workHome', 'btnVacancy') } <FeatherIcon icon="arrow-right" />
                            </Link>
                          </div>
                        </article>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </section>
        )}
        { team && (
          <section className="less-margin">
            <div className="content">
              <h2 className="no-margin" style={{ backgroundColor: '#FED417', color: '#000', float: 'left', marginBottom: 10 }}><span>{ getTxt('workHome', 'what') }</span><span>{ getTxt('workHome', 'ourteam') }</span></h2>
              <h3 style={{ width: '100%', float: 'left' }}>Probably the coolest recruitment ageny in the universe!</h3>
            </div>
            <div className="content">
              <div className="column-2n" style={{ float: 'left', width: '100%', marginTop: 30 }}>
                <div className="column">
                  <Member data={ team[currentTeam].attributes } />
                </div>
                <div className="column column-box">
                  <Swiper navigation={true} onSlideChange={(e) => setCurrentTeam(e.activeIndex) }  pagination={true} modules={[Navigation, Pagination]} className="mySwiper">
                    {
                      team.map((value, key) => {
                        return (
                          <SwiperSlide key={ key }>
                            <h4>{ value.attributes.name }</h4>
                            <span className="job">{ value.attributes.functionTitle }</span>
                            <span className="description" dangerouslySetInnerHTML={{__html: value.attributes.quote}} />
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        )}

      </div>
    ) : <Loading />
  );
}

export default HomeWerken;