//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Loading from "@components/Loading";
import { Navigation, Pagination } from "swiper";
import moment from 'moment';

const Blog = ({ section }) => {
  const [blog, setBlog] = useState(false);

  useEffect(() => {
    let populate_blog = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/blogs"+populate_blog)
      .then(res => res.json()).then(blog => {
        setBlog(blog.data);
    })
   }, [section]);

  return (
    blog ? (
      <div className="blog-section">
        <section className="less-margin headline">
          <div className="content">
            <h1>Blog</h1>
          </div>
        </section>
        <div className="content">
          <div className="blog-items">
            {
              blog.map((value, key) => {
                return (
                  <Link className="btn-blog" to={`/${section}/blog/${value.id}` }>
                    <div className="blog-image">
                      <div className="image" style={{ backgroundImage: `url(${value.attributes.heroImage.data.attributes.formats.large.url})` }}></div>
                    </div>
                    <div className="blog-item">
                      <span className="tag">{ value.attributes.category }</span>
                      <span className="date">{ moment(value.attributes.title.updatedAt).format('MMM D, YYYY') }</span>
                      <h4>{ value.attributes.title.substring(0, 50) + '...' }</h4>
                      {/*<hr />
                      <div className="author-block">
                        <div className="author-img" style={{ backgroundImage: 'url(https://picsum.photos/400/600?rand=3' }}></div>
                        <span className="author">Marvin McKinney</span>
                      </div>*/}
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </div>
      </div>
    ) : <Loading />
  );
}

export default Blog;