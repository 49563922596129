import { locales } from './translate.js';

export const getCurrentLanuage = () => {
  let language = localStorage.getItem("jdj_lanuage");
  let supported = ['en', 'fr-BE'];
  if(!language){
    return 'en';
  } else if(supported.includes(language)){
    return language;
  }
}

export const setCurrentLanguage = (language) => {
  let supported = ['en', 'fr-BE'];
  if(supported.includes(language)){
    localStorage.setItem("jdj_lanuage", language);
  } else {
    console.log('language not supported');
  }
}

export const getTxt = (section, str) => {
  let language = getCurrentLanuage();
  language = (language == 'fr-BE') ? 'fr' : language;
  return locales[section][language][str];
}