//libs
import { useState, useEffect } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Loading from "@components/Loading";
import ApplyForm from "@components/ApplyForm";
import { getCurrentLanuage, getTxt } from '@data/locales';

const PositionsDetails = ({section}) => {

  const [content, setContent] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    let populate_blog = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/open-posities/"+id+populate_blog)
      .then(res => res.json()).then(jobs => {
        setContent(jobs.data.attributes);
    })
   }, [section]);

  return (
    content ? (
      <div className="job-details">
        <section className="headline">
          <div className="content">
            <div className="half-text">
              <h1>{ content.title }</h1>
              <span className="intro-text">
                { content.introText }
              </span>
            </div>
          </div>
        </section>
        <div className="job-content white">
          <div className="job-text" dangerouslySetInnerHTML={{__html: content.content}} />
        </div>
        <div className="content image-grid">
          <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/jorissen_656148724e.jpeg?updated_at=2023-01-30T04:33:41.482Z)` }}></div>
          <div className="image" style={{ backgroundImage: `url(https://strapi-aws-s3-image-bucket.s3.eu-west-2.amazonaws.com/cover_02dba5bdd8.jpg?updated_at=2022-12-26T17:44:47.130Z)` }}></div>
        </div>
        <div className="job-content" style={{textAlign:'center'}}>
          <div className="job-text">
            <h3>{ getTxt('positionsDetails', 'title1') }</h3>
            <span className="description">
              { getTxt('positionsDetails', 'description1') }
            </span>
          </div>
        </div>
        <div className="process">
          <div className="content">
            <div className="process-row first">
              <div className="process-item">
                <div className="square ico-ambition"></div>
                <h4>{ getTxt('positionsDetails', 'title2') }</h4>
                <span className="description">
                  { getTxt('positionsDetails', 'description2') }
                </span>
              </div>
              <div className="process-item">
                <div className="square ico-movement"></div>
                <h4>{ getTxt('positionsDetails', 'title3') }</h4>
                <span className="description">
                  { getTxt('positionsDetails', 'description3') }
                </span>
              </div>
              <div className="process-item">
                <div className="square ico-enjoy"></div>
                <h4>{ getTxt('positionsDetails', 'title4') }</h4>
                <span className="description">
                  { getTxt('positionsDetails', 'description4') }
                </span>
              </div>
            </div>
            <div className="process-row">
              <div className="process-item">
                <div className="square ico-chances"></div>
                <h4>{ getTxt('positionsDetails', 'title5') }</h4>
                <span className="description">
                  { getTxt('positionsDetails', 'description5') }
                </span>
              </div>
              <div className="process-item">
                <div className="square ico-assertive"></div>
                <h4>{ getTxt('positionsDetails', 'title6') }</h4>
                <span className="description">
                  { getTxt('positionsDetails', 'description6') }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="job-content howto-wrapper">
          <div className="job-text">
            <h3>{ getTxt('positionsDetails', 'title7') }</h3>
            <div className="how-to">
              <div className="howto-item">
                <div className="index">1</div>
                <div className="howto-content">
                  <h4>{ getTxt('positionsDetails', 'title8') }</h4>
                  <span className="description">
                    { getTxt('positionsDetails', 'description8') }
                  </span>
                </div>
              </div>
              <div className="howto-item">
                <div className="index">2</div>
                <div className="howto-content">
                  <h4>{ getTxt('positionsDetails', 'title9') }</h4>
                  <span className="description">
                    { getTxt('positionsDetails', 'description9') }
                  </span>
                </div>
              </div>
              <div className="howto-item">
                <div className="index">3</div>
                <div className="howto-content">
                  <h4>{ getTxt('positionsDetails', 'title10') }</h4>
                  <span className="description">
                    { getTxt('positionsDetails', 'description10') }
                  </span>
                </div>
              </div>
              <div className="howto-item">
                <div className="index">4</div>
                <div className="howto-content">
                  <h4>{ getTxt('positionsDetails', 'title11') }</h4>
                  <span className="description">
                    { getTxt('positionsDetails', 'description11') }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content content-form">
          <div className="column column-form">
            <ApplyForm title={ getTxt('positionsDetails', 'soli')+` ${content.title}`} />
          </div>
        </div>
      </div>
    ) : <Loading />
  );
}

export default PositionsDetails;