//libs
import React from "react";
import logo from '@assets/images/not-found.png';
import { Link, useNavigate } from "react-router-dom";
import MainButton from "@components/MainButton";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="content">
        <div className="left">
          <h2 className="no-margin">Error 404</h2>
          <div className="description">
            De pagina die u zoekt is verplaatst, verwijderd of heeft misschien nooit bestaan.
          </div><br /><br /><br />
          <MainButton link={ '/' }>Naar homepagina</MainButton>
        </div>
        <div className="right">
          <img className="logo" alt="Jorissen & De Jonck" src={ logo } width="100%" />
        </div>
      </div>
    </div>
  );
}

export default NotFound;