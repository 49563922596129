import { useState, useEffect, useRef } from 'react';
import { getTxt } from '@data/locales';
import shape from '@assets/images/shape.svg';

const CookiePopup = () => {

  const [accepted, setAccepted] = useState(false);

  useEffect(()=> {
    let cookies = localStorage.getItem("jdj_cookies");
    if(cookies){
      setAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("jdj_cookies", "0dESI3vNa3");
    setAccepted(true);
  }

  return(
    <>{ !accepted && (
      <div className="popup">
        <div className="popup-background" />
        <div className="popup-inner">
          <div className="cookies-popup">
            <img className="logo" alt="Jorissen & De Jonck" src={ shape } width="30" />
            <h3>{ getTxt('cookies', 'title') }</h3>
            <p className="description">
              { getTxt('cookies', 'text') } 
            </p>
            <p>{ getTxt('cookies', 'more') } <a href="/cookies">{ getTxt('cookies', 'link') }</a>.</p>
            <div className="cookies-buttons">
              <a href="#" onClick={ () => acceptCookies() } className="btn-main">{ getTxt('cookies', 'accept') } </a>
              <a href="#" onClick={ () => acceptCookies() } className="btn-main decline">{ getTxt('cookies', 'decline') } </a>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export default CookiePopup;