import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import { getCurrentLanuage, getTxt } from '@data/locales';

const MakeAppointmentTalentForm = ({ title }) => {
  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [name, setName] = useState('');
  const [functionTitle, setFunction] = useState('');
  const [sector, setSector] = useState('');
  const [interest, setInterest] = useState('');
  const [insearchof, setInsearchof] = useState('');
  const [region, setRegion] = useState('');
  const [phone, setPhone] = useState('');
  const [when, setWhen] = useState('');

  const options = [{ value: 'finance', label: 'Finance' },{ value: 'bouw', label: 'Bouw' },{ value: 'engineering', label: 'Engineering' }];
  const optionsSearch = [{ value: 'new_job', label: 'Nieuwe job' }, { value: 'advice', label: 'Advies' }];
  const [optionsSubniches, setOptionsSubniches] = useState(false);


  const [email, setEmail] = useState('');
  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let _interest = [];
      interest.map((value, key) => {
        _interest.push(value.label);
      })
      let data = { name: name, functionTitle: functionTitle, sector: sector.label, email: email, region: region, phone: phone, interest: _interest.toString() };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/contact-submissions', { data: data });
        emailjs.send('service_oek44me', 'template_bmx9nb7', data, 'HHWiFp7FolbhB5lK6').then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  const validateFields = () => {
    setError(false);
    if(name == '' || functionTitle == '' || sector  == '' || interest  == '' || region == '' || phone == ''){
      setError(getTxt('forms', 'error'));
      return false;
    } else {
      return true;
    }
  }

  const slugify = (str) => {
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }

  useEffect(() => {
    let populate_niches = '?populate=*';
    fetch(process.env.REACT_APP_STRAPI+"/api/niche-categories"+populate_niches)
      .then(res => res.json()).then(niches => {
        let _options = [];
        niches.data.map((value, key) => {
          _options.push({ value: slugify(value.attributes.category), label: value.attributes.category })
        })
        setOptionsSubniches(_options);
    })
  }, []);

  return(
    <div className="contact-form">
      {
        thanks ? <div className="thanks"><h3>{ getTxt('forms', 'thanks') }</h3></div> : (
          <form ref={form} onSubmit={(event) => { handleSubmit(event) } }>
            <h3>{ title }</h3>
            <label>{ getTxt('forms', 'full_name') } *</label>
            <input type="text" name="name" onChange={ (event) => setName(event.target.value) } />

            <label>{ getTxt('forms', 'function') } *</label>
            <input type="text" name="name" onChange={ (event) => setFunction(event.target.value) } />
            <label>{ getTxt('forms', 'activein') } *</label>
            <Dropdown options={options} value={sector} onChange={ (event) => { setSector(event) } }  />
            
            <input type="hidden" name="name" onChange={ (event) => setRegion(event.target.value) } />
            {/*onChange={ (event) => setSector(event.target.value) }  */}
            {
              optionsSubniches && (
                <>
                  <label>{ getTxt('forms', 'interest') } *</label>
                  <Select className="react-select-container" isMulti  classNamePrefix="react-select" onChange={ (event) => { setInterest(event) } } options={optionsSubniches} />
                </>
              )
            }
            <label>{ getTxt('forms', 'region') } *</label>
            <input type="text" name="name" onChange={ (event) => setRegion(event.target.value) } />
            
            {/*
              <label>Op zoek naar *</label>
            <Dropdown options={optionsSearch} />
            */}
            
            <label>{ getTxt('forms', 'mobile') } *</label>
            <input type="number" name="name" onChange={ (event) => setPhone(event.target.value) } />

            <label>{ getTxt('forms', 'email') }</label>
            <input type="text" name="email" onChange={ (event) => setEmail(event.target.value) } />
            {/*
            <label>Wanneer contacteren we jouw het best?</label>
            <input type="text" name="name" onChange={ (event) => setWhen(event.target.value) } />*/}
            { error && <span className="error">{ error }</span> }
            <input type="submit" value={ getTxt('forms', 'send') } className="btn-main" />
          </form>
        )
      }
    </div>
  )
}

export default MakeAppointmentTalentForm;